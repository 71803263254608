'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./User').default} User
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('./Tooltip').default} Tooltip
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./XtraService').default} XtraService
 * @typedef {import('./TrackingService').default} TrackingService
 * @typedef {import('./ChannelService').default} ChannelService
 * @typedef {import('./MetadataService').default} MetadataService
 * @typedef {import('../antenne-frontend').MediaId} MediaId
 * @typedef {import('../antenne-frontend').MediaType} MediaType
 * @typedef {import('../antenne-frontend').MediaMetadata} MediaMetadata
 * @typedef {import('../antenne-frontend').Image} Image
 * @typedef {import('../openapi-generated').Url} Url
 * @typedef {import('../openapi-generated').Cover} ApiCover
 * @typedef {Omit<ApiCover, 'sizes'> & { sizes: ['200x200','300x300','600x600'] }} Cover
 * @typedef {import('../openapi-generated').Channel} ApiChannel
 * @typedef {Omit<ApiChannel, 'logo'> & { logo: Image[] }} Channel
 * @typedef {import('../openapi-generated').Station} Station
 * @typedef {import('../antenne-api').Metadata} Metadata
 * @typedef {import('../openapi-streamingserver-generated').CompanionAd} CompanionAd
 * @typedef {import('../openapi-streamingserver-generated').StreamMetaTitle} StreamingMetadataMessage
 * @typedef {StreamingMetadataMessage & {}} MetadataMessage
 * @typedef {{
 *   artist: string,
 *   title: string,
 *   cover: Url
 * }} MetadataFallback
 * @typedef {{
 *   title: string,
 *   artist: string,
 *   type: string | undefined,
 *   stream: string,
 *   occurredon: string,
 *   identifier: string,
 *  }} WebsocketMetadataItem
 * @typedef {{
 *   playerid: string,
 *   station: Station,
 *   streamhost: string,
 *   channel: Channel,
 * }} AntenneConfig
 * @typedef {{
 *   hasPlayedXtraBefore: boolean | undefined,
 *   acceptedConsentTemplateIds: string[] | undefined,
 * }} AudioPlayerPlayOptions
 */

import ClassLogger from 'ClassLogger';
import ProgressBar from './playbar/Progressbar';
import VolumeControl from './playbar/VolumeControl';
import ConsentMissingError from './ConsentMissingError';

export default class AudioPlayer {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Audioplayer';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {TrackingService} trackingService
     * @param {Tooltip} tooltip
     * @param {EventEmitter} eventEmitter
     * @param {MetadataService} metadataService
     * @param {ChannelService} channelService
     * @param {User} user
     * @param {NavigationHandler} navigationHandler
     * @param {XtraService} xtraService
     */
    // eslint-disable-next-line max-len
    constructor (commonMethods, trackingService, tooltip, eventEmitter, metadataService, channelService, user, navigationHandler, xtraService) {
        this.version = '6.0.0';

        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        if (document.documentElement.classList.contains('template--accountminimal')) {
            this.logger.debug('Stopping init because of template');
            return;
        }

        /** @type {AntenneConfig} */
        this.config = window.antenne.config;
        this.user = user;
        this.tooltip = tooltip;
        this.xtraService = xtraService;
        this.eventEmitter = eventEmitter;
        this.commonMethods = commonMethods;
        this.trackingService = trackingService;
        this.navigationHandler = navigationHandler;

        /** @type {MetadataFallback} */
        this.fallbackMetadata = {};
        this.fillFallbackMetadata(this.config.channel).updateUi();

        /** @type {MediaType} */
        this.mediaType = undefined;
        /** @type {MediaId} */
        this.mediaId = undefined;
        /** @type {MediaMetadata} */
        this.mediaMetadata = undefined;
        this.previousTrackCurrentTimeInSeconds = undefined;

        // This base64-encoded data is a 1s silent wav file … used to fix a permission issue.
        // eslint-disable-next-line max-len
        this.defaultAudioPlayerSrc = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA';

        /** @type {WebSocket | undefined} */
        this.streamingServerWebsocket = undefined;
        /** @type {WebsocketMetadataItem} */
        this.currentMetadataItem = {};

        this.localStorageMediaKey = 'aby-webplayer-currenly-playing-media';

        this.channelService = channelService;
        this.metadataService = metadataService;
        this.metadataService.setAudioPlayer(this);

        /**
         * This option is used to determine whether this playbar was playing a given
         * audio stream. In case a player on the site (e.g. video) starts playing,
         * we interrupt the audio and resume audio when video finished playing.
         */
        this.shouldResumeAudioStream = false;

        /**
         * This option is used as a specific marker when audio plays in a webview of
         * the native apps. When clicking a play button in a webview of a native
         * app, we still want everything else to work and use this toggle to
         * determine whether audio "is playing", even though audio is not
         * playing in the audio element we’re managing here in this JS.
         */
        this.isPlayingOnNativeApp = false;

        this.navigationHandler.on('ready', () => {
            this.audioElement = this.createAudioElement();
            this.volume = new VolumeControl(this.audioElement);

            this.progressbar = new ProgressBar(this);

            this.assignClickListenerToSeekBackButton();
            this.assignClickListenerToSeekForwardButton();

            return this.init();
        }).on('render', () => {
            this.checkPlaybuttonsState();
        });
    }

    /**
     * Create and initialize an HTML5 audio element with autoplay.
     *
     * @returns {HTMLAudioElement}
     */
    createAudioElement () {
        this.logger.log('Creating playbar <audio> HTML element');

        const audio = document.createElement('audio');

        if (window.nativeJsBridge.isWebview) {
            return audio;
        }

        audio.id = 'antenneplayernode';
        // audio.src = '';
        audio.src = this.defaultAudioPlayerSrc;
        audio.volume = 0.8;
        audio.autoplay = false;
        audio.controls = 'controls';

        // Reset src (cancel any further stream loading)
        audio.load();

        const nativeAudioPlayer = document.querySelector('[data-abywebplayernative]');

        if (nativeAudioPlayer) {
            nativeAudioPlayer.appendChild(audio);
        }

        audio.addEventListener('playing', () => this.onPlaying());
        audio.addEventListener('pause', () => this.onPaused());
        audio.addEventListener('ended', () => this.onEnded());
        // audio.addEventListener('stalled', () => this.onStalled());
        audio.addEventListener('waiting', () => this.onWaiting());
        // audio.addEventListener('error', (error) => this.onError(error));
        audio.addEventListener('playerror', () => this.onPlayError());
        // audio.addEventListener('playblocked', () => this.playerError());

        return audio;
    }

    /**
     * Adjust the player UI after the player started playing.
     */
    checkPlaybuttonsState () {
        const buttons = document.querySelectorAll(`[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`);

        this.isPlaying()
            ? buttons.forEach(button => button.classList.add('is-playing'))
            : buttons.forEach(button => button.classList.remove('is-playing'));
    }

    /**
     * Adjust the player UI after the player started playing.
     */
    onPlaying () {
        this.logger.log('audio:playing event fired');

        document
            .querySelectorAll(`[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`)
            .forEach(button => {
                button.classList.remove('is-loading');
                button.classList.add('is-playing');
            });

        navigator.mediaSession.playbackState = 'playing';
    }

    /**
     * Adjust the player UI after the audio ended.
     */
    async onEnded () {
        this.logger.log('audio:ended event fired');

        this.eventEmitter.emit('audio:stop', {
            mediaType: this.mediaType,
            mediaId: this.mediaId,
        });

        this.trackMediaCompleteEvent();
        this.currentMetadataItem = {};

        /** @type {AudioPlayerPlayOptions} */
        const audioPlayerOptions = {
            hasPlayedXtraBefore: false,
        };

        if (this.mediaType === 'xtra') {
            audioPlayerOptions.hasPlayedXtraBefore = true;
            this.xtraService.openXtraOutroOverlay();
        }

        await this.restoreAndStartPreviouslyPlayedStream(audioPlayerOptions);
    }

    /**
     * Adjust the player UI when the player paused.
     */
    onPaused () {
        this.logger.log('audio:pause event fired');

        this.eventEmitter.emit('audio:stop', {
            mediaType: this.mediaType,
            mediaId: this.mediaId,
        });

        this
            .trackStreamStopEvent()
            .trackMediaCancelEvent();

        this.currentMetadataItem = {};

        document
            .querySelectorAll(`[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`)
            .forEach(button => {
                button.classList.remove('is-playing');
            });

        navigator.mediaSession.playbackState = 'paused';

        this.progressbar.repaint().stop();
    }

    /**
     * Wait for the audio stream to start.
     */
    onWaiting () {
        if (!this.audioElement) {
            throw new Error('Can not pause playing because player is not initialized.');
        }

        this.logger.log('Waiting for audio stream', { mediaType: this.mediaType, mediaId: this.mediaId });

        document
            .querySelectorAll(`[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`)
            .forEach(button => {
                button.classList.remove('is-playing');
                button.classList.add('is-loading');
            });
    }

    /**
     * Handle the "playerror" event.
     */
    onPlayError () {
        if (!this.audioElement) {
            this.logger.warn('Cannot pause playing because player is not initialized.');
            return;
        }

        this.logger.error('Media "playerror" event received', { mediaType: this.mediaType, mediaId: this.mediaId });

        if (this.hasPlayerDomElement()) {
            this.playerDomElement().classList.add('has-error');
        }

        this.setTitleInPlayerUi('Kann nicht abgespielt werden');

        this.showOrHideProgressBarForTrack();
    }

    /**
     * Global Initialize method
     *
     * @returns {Promise<this>} the initialization promise resolves when everything is initialized
     */
    async init () {
        this.logger.log(`Initializing webradio player v${this.version}`);

        this.attachUiEventListeners();

        if (!document.querySelector('[data-abywebplayer]')) {
            // player markup is not available, don’t initialize the player
            this.logger.info('Could not find audio player markup. Not initializing the audio player');
            return;
        }

        this.registerEventListeners();
        await this.findMediaToPlay();

        this.logger.log('Initialized');

        return this;
    }

    /**
     * Tbd.
     *
     * @returns {this}
     */
    attachUiEventListeners () {
        // this.attachAirplayListeners();

        document.addEventListener('click', async (event) => {
            let playbutton;

            if (event.target.dataset && event.target.dataset.play && event.target.dataset.playType) {
                playbutton = event.target;
            } else {
                playbutton = event.target.closest('[data-play][data-play-type]');
            }

            if (playbutton) {
                event.preventDefault();
                this.logger.info('Click on a play button detected', playbutton.dataset);

                let metadata;

                try {
                    metadata = typeof playbutton.dataset.metadata === 'string'
                        ? JSON.parse(playbutton.dataset.metadata)
                        : playbutton.dataset.metadata;
                } catch (error) {
                    this.logger.warn('Invalid "metadata" JSON value on playbutton. Ignoring the metadata', {
                        playbutton,
                    });
                }

                try {
                    await this.togglePlayPause(playbutton.dataset.playType, playbutton.dataset.play, metadata);
                } catch (error) {
                    this.logger.error('Failed to start playing after clicking a play button', error);
                }
            }
        });

        document.addEventListener('clickCompanionAdTracking', async (event) => {
            await this.handleCompanionAdClick(event.detail);
        });

        return this;
    }

    /**
     * Listen for airplay/chromecast events if such events are available in the user’s browser.
     *
     * @returns {this}
     */
    attachAirplayListeners () {
        this.logger.log('Attaching event listeners to airplay button');

        if (this.isMissingAirplaySupport()) {
            this.logger.log('Airplay not available. Hiding the button and waiting for airplay device');
            this.hideAirplayButton();
        }

        this.airplayButton().addEventListener('click', () => {
            this.airplayButton().webkitShowPlaybackTargetPicker();
        });

        this.audioElement.addEventListener('webkitplaybacktargetavailabilitychanged', event => {
            this.logger.log('Received airplay target availability change event', event);

            switch (event.availability) {
                case 'available':
                    this.logger.log('Apple Airplay events available. Registering airplay listener');
                    this.showAirplayButton();
                    break;

                case 'not-available':
                    this.logger.log('Apple Airplay events not available.');
                    this.hideAirplayButton();
                    break;

                default:
                    this.logger.log(`Unknown airplay availability: ${event.availability}`);
            }
        });

        return this;
    }

    /**
     * Create and initialize the seek back button.
     */
    assignClickListenerToSeekBackButton () {
        const seekBackButton = document.querySelector('[data-abywebplayer-seek-back]');

        if (seekBackButton) {
            seekBackButton.onclick = () => this.seekBack();
        }
    }

    /**
     * Seek the given `seconds` back. By default, seeks 10 seconds back.
     */
    seekBack (seconds = 10) {
        this.assignProgressInSeconds(
            Math.max(0, this.audioElement.currentTime - seconds),
        );

        this.progressbar.repaint();
    }

    /**
     * Create and initialize the seek forward button.
     */
    assignClickListenerToSeekForwardButton () {
        /** @type {HTMLButtonElement} */
        const seekForwardButton = document.querySelector('[data-abywebplayer-seek-forward]');

        if (seekForwardButton) {
            seekForwardButton.onclick = () => this.seekForward();
        }
    }

    /**
     * Seek the given `seconds` forward. By default, seeks 10 seconds forward.
     */
    seekForward (seconds = 10) {
        this.assignProgressInSeconds(
            Math.min(this.audioElement.duration, this.audioElement.currentTime + seconds),
        );

        this.progressbar.repaint();
    }

    /**
     * Determine whether the user’s browser is not supporting Apple’s airplay and Google Cast events.
     *
     * @returns {Boolean}
     */
    isMissingAirplaySupport () {
        return window.WebKitPlaybackTargetAvailabilityEvent === undefined; // && this.googleCast.isNotSupported();
    }

    /**
     * Mark the player as available for airplay.
     *
     * @returns {this}
     */
    showAirplayButton () {
        this.airplayButton().classList.remove('u-hide');

        return this;
    }

    /**
     * Mark the player as not available for airplay.
     *
     * @returns {this}
     */
    hideAirplayButton () {
        this.airplayButton().classList.add('u-hide');

        return this;
    }

    /**
     * @returns {this}
     */
    registerEventListeners () {
        window.addEventListener('beforeunload', () => {
            this.logger.log('Running callback triggered by "beforeunload" browser event');

            this
                .saveCurrenlyPlayingStream()
                .closeStreamingServerWebsocketConnection()
                .trackMediaCancelEvent();
        });

        /**
         * We’re dispatching this event after the user explicitly accepted a consent
         * for a given templateId. The event contains the accepted template IDs in
         * the `event.detail` field. From here, we’re passing accepted consents
         * along to the play method which uses them to ensure we can play media.
         */
        document.addEventListener('antenne.audio.play.withAcceptedConsents', async (event) => {
            const acceptedConsentTemplateIds = event && event.detail
                ? [].concat(event.detail.acceptedTemplateIds)
                : [];

            await this.play(
                this.mediaType,
                this.mediaId,
                this.mediaMetadata,
                { acceptedConsentTemplateIds },
            );
        });

        this.eventEmitter.on('metadata:now', metadata => {
            this.handleEmittedMetadata(metadata);
        });

        this.eventEmitter.on('video:play', async () => {
            this.logger.log('Received video:play event');

            this.shouldResumeAudioStream = this.isPlaying();
            await this.pause(this.mediaType, this.mediaId);
        });

        this.eventEmitter.on('video:pause', async () => {
            this.logger.log('Received video:pause event');

            if (this.shouldResumeAudioStream) {
                await this.play(this.mediaType, this.mediaId, this.mediaMetadata);
            }
        });

        return this;
    }

    /**
     * @param {StreamingMetadataMessage} message
     */
    async handleStreamingMetadata (message) {
        if (!message || !message.title || !message.artist) {
            this.logger.log('Ignoring metadata message without title or artist', message);
            return;
        }

        this.logger.log('Handling metadata message', message);

        message.class = message.class && message.class.toLowerCase();

        const channelConfig = await this.channelConfigByChannelKey(this.mediaId);

        const metadataItem = {
            title: message.title,
            artist: message.artist,
            type: message.class,
            stream: channelConfig.stream.mountpoint,
            occurredon: (new Date(message.start_time_unix * 1000)).toISOString(),
            identifier: (
                message.master_id || message.title_combined || message.title + message.artist
            ).replace(/[^A-Za-z0-9']/g, ''),
        };

        // this may happen when receiving metadata from the API which comes at intervals and not on audio change
        const isSameMetadata = this.currentMetadataItem.title === metadataItem.title &&
                                this.currentMetadataItem.artist === metadataItem.artist &&
                                this.currentMetadataItem.type === metadataItem.type;

        if (isSameMetadata) {
            return;
        }

        if (this.isPlaying()) {
            this.trackMediaCompleteEvent(metadataItem.occurredon);
        }

        if (this.isPlaying()) {
            this.currentMetadataItem = metadataItem;
        }

        if (message.class === 'music') {
            this.currentMetadataItem.type = 'song';
        }

        /*
         * 2023-08-11 disabled as its not always working like it should with bad metadata send by stations
         * @see https://secure.helpscout.net/conversation/2303276399/14290?folderId=1904971
         *
        if (message.class === 'news') {
            message.title = 'Nachrichten';
            this.currentMetadataItem.type = 'news';
            this.logger.log('News metadata detected', message);
        }

        if (message.class === 'traffic') {
            message.title = 'Verkehrsservice';
            this.currentMetadataItem.type = 'service';
            this.logger.log('Traffic metadata detected', message);
        }

        if (message.class === 'weather') {
            message.title = 'Wetter';
            this.currentMetadataItem.type = 'service';
            this.logger.log('Weather metadata detected', message);
        }
        */

        if (message.class === 'advertisement') {
            this.currentMetadataItem.type = 'advertising';
            this.logger.log('Advertisement detected', message);

            if (message.companion_ad) {
                await this.handleCompanionAd(message.companion_ad, message.title);
            } else {
                this.logger.log('Advertisement detected but no companion ad found.');
            }
        }

        // If we have a cover_data, we prefix it here once with the base64 string
        if (message.cover_data) {
            message.cover_data = `data:image/jpeg;charset=utf-8;base64,${message.cover_data}`;
        }

        if (message.cover_data) {
            metadataItem.cover = message.cover_data;
        } else if (message.cover_url) {
            metadataItem.cover = message.cover_url;
        }

        if (this.canUpdatePlayerUi(message)) {
            const cover = message.cover_data
                ? message.cover_data
                : message.cover_url;

            this
                .updateUi({
                    title: channelConfig.title,
                    artist: `${message.artist} – ${message.title}`,
                    cover,
                })
                .adjustBookmarkLikeIconForCurrentlyPlayingMedia(message);

            if (this.isPlaying()) {
                this.createMediaSession({
                    title: message.title,
                    artist: message.artist,
                    album: channelConfig.title,
                    artwork: this.createMediaSessionArtwork({
                        master_id: message.master_id,
                        cover_url: message.cover_url,
                        cover_data: message.cover_data,
                    }),
                });
            }
        } else {
            this
                .updateUi()
                .adjustBookmarkLikeIconForCurrentlyPlayingMedia();

            if (this.isPlaying()) {
                this.createMediaSession({
                    album: this.config.station.name || '',
                    title: this.fallbackMetadata.title,
                    artist: this.fallbackMetadata.artist,
                    artwork: this.createMediaSessionArtwork({
                        cover_url: this.fallbackMetadata.cover,
                    }),
                });
            }
        }

        if (this.isPlaying()) {
            this.trackMediaStartEvent(metadataItem.occurredon);
        }
    }

    /**
     * Determine whether the given `message` can update the player UI.
     *
     * @param {StreamingMetadataMessage} message
     *
     * @returns {Boolean}
     */
    canUpdatePlayerUi (message) {
        return message &&
        message.title &&
        message.artist &&
        message.class.toLowerCase() === 'music';
        // ['music', 'news', 'traffic', 'service', 'weather'].includes(message.class.toLowerCase());
    }

    /**
     * @param {CompanionAd} companionAd
     * @param {String} title
     */
    async handleCompanionAd (companionAd, title) {
        this.logger.log('Showing companion ad', companionAd);

        const resourceUrl = String(companionAd.resource_url || '').trim();
        const resourceData = String(companionAd.resource_data || '').trim();

        if (!resourceUrl && !resourceData) {
            this.logger.debug('Not showing companion ad: missing image URL or data');
            return;
        }

        const resourceType = String(companionAd.resource_type || '').trim();

        const imageUrl = resourceData.length > 0 && resourceType.length > 0
            ? `data:${resourceType};base64, ${resourceData}`
            : resourceUrl;

        const clickTracking = []
            .concat(companionAd.click_tracking || [])
            .join('||');

        const onclick = `document.dispatchEvent(
            new CustomEvent('clickCompanionAdTracking', { detail: { clickTracking: '${clickTracking}' } })
        )`;

        const content = `
            <a href="${companionAd.click}" target="_blank" onclick="${onclick}">
                <img src="${imageUrl}" alt="${title}" />
            </a>
        `;

        this.eventEmitter.emit('dialog.renderAndShow', {
            content,
            title: '',
            description: '',
            classes: 'c-dialog--companion-ad c-dialog--show-overflow',
        });

        const viewTracking = [].concat(companionAd.view_tracking || []);

        for (const link of viewTracking) {
            fetch(link, { method: 'GET' }).catch(error => {
                this.logger.warn('Failed to track view for companion ad ', { error, link });
            });
        }
    }

    /**
     * @param {{clickTracking?: string }} data
     */
    async handleCompanionAdClick (data) {
        this.logger.log('Handling companion ad click', data);

        const clickTracking = String(data.clickTracking || '').split('||');

        for (const link of clickTracking) {
            fetch(link, { method: 'GET' }).catch(error => {
                this.logger.warn('Failed to track click for companion ad ', { error, link });
            });
        }
    }

    /**
     * @param {Metadata[]} metadataItems
     */
    async handleEmittedMetadata (metadataItems = []) {
        try {
            if (this.hasStreamingServerWebsocketConnection()) {
                this.logger.log('Ignoring metadata from API, using existing websocket connection to streaming server');
                return;
            }

            if (this.mediaType !== 'channel') {
                this.logger.log('Ignoring metadata from API because currently played media type is not "channel"', {
                    currentlyPlayedMediaType: this.mediaType,
                });
                return;
            }

            this.logger.log('Received metadata from API. Updating playbar with currently playing media details');

            const streamConfig = await this.getStreamConfig() || {};

            const metadata = metadataItems.find(item => {
                return item.mountpoint === streamConfig.mountpoint;
            }) || {};

            await this.handleStreamingMetadata(
                this.transformMetadataToStreamingMessage(metadata),
            );
        } catch (error) {
            this.logger.warn('Failed to handle emitted metadata', { error });
        }
    }

    /**
     * Returns a streaming metadata object derived from the given `metadata`.
     *
     * @param {Metadata} metadata
     *
     * @returns {StreamingMetadataMessage}
     */
    transformMetadataToStreamingMessage (metadata) {
        return {
            class: metadata.class,
            title: metadata.title,
            artist: metadata.artist,
            master_id: metadata.masterid,
            cover_url: this.createCoverUrl(metadata.cover),
            start_time_unix: Math.floor(new Date(metadata.starttime).getTime() / 1000),
        };
    }

    /**
     * Save the previously played media when the player is currently playing audio
     * and it’s interrupted by an xtra. We’re resuming the previous stream when
     * the xtra has finished playing.
     *
     * @returns {this}
     */
    saveCurrenlyPlayingStream () {
        if (this.isPlaying()) {
            this.logger.log('Saving currently playing stream information');
            this.shouldResumeAudioStream = true;

            localStorage.setItem(this.localStorageMediaKey, JSON.stringify({
                type: this.mediaType,
                mediaId: this.mediaId,
                metadata: this.mediaMetadata,
                currentTime: this.audioElement.currentTime,
            }));
        } else {
            this.logger.log('Audio player not playing. Not saving any playing/src information');
        }

        return this;
    }

    /**
     * Restore a previously played stream and start it. Does not start a stream
     * if the user didn’t play anything before.
     *
     * @param {AudioPlayerPlayOptions} options
     *
     * @returns {Boolean}
     */
    async restoreAndStartPreviouslyPlayedStream (options = {}) {
        this.logger.log('Attempting to play previously played media');

        const previousPlayedMedia = localStorage.getItem(this.localStorageMediaKey);

        if (previousPlayedMedia) {
            this.logger.log('Found previously played media', previousPlayedMedia);

            /** @type {{type: MediaType, id: MediaId, metadata: MediaMetadata, currentTime: number}} */
            const { type, mediaId, metadata, currentTime } = JSON.parse(previousPlayedMedia);

            await this.play(type, mediaId, metadata || {}, options);

            if (currentTime && type !== 'channel') {
                this.assignProgressInSeconds(currentTime);
            }

            localStorage.removeItem(this.localStorageMediaKey);

            return true;
        }

        this.logger.log('No previous media found which should be resumed. Checking whether an xtra was playing before');

        if (this.mediaType === 'xtra') {
            this.logger.log('Previously played an xtra: starting default channel now');
            await this.play('channel', this.config.channel.channelkey, {}, options);

            return true;
        }

        this.logger.log('Not starting any previous media item');

        return false;
    }

    /**
     * Create a websocket connection to the streaming server.
     *
     * @returns {this}
     */
    createWebsocketConnectionToStreamingServer () {
        if (this.hasStreamingServerWebsocketConnection()) {
            // eslint-disable-next-line max-len
            this.logger.error('This should not happen. Please fix me. Websocket connection to streaming server exists. Closing it before starting a new one.');
            this.closeStreamingServerWebsocketConnection();
        }

        if (this.mediaType !== 'channel' && this.mediaType !== 'xtra') {
            return this;
        }

        const url = this.createWebsocketStreamingServerUrl();
        this.logger.log(`Connecting to websocket server using URL: ${url}`);

        this.streamingServerWebsocket = new WebSocket(url);

        this.streamingServerWebsocket.onopen = () => {
            this.logger.log(`Connected to websocket server on URL: ${url}`);
        };

        this.streamingServerWebsocket.onclose = (event) => {
            this.logger.log('Closed websocket connection', { event });
        };

        this.streamingServerWebsocket.onerror = (event) => {
            this.logger.log('Received websocket error', { event });

            // note: implement reconnect logic?
            // if (event.code === 'ECONNREFUSED') {
            // return this.reconnect();
            // }
        };

        this.streamingServerWebsocket.onmessage = (event) => {
            this.handleStreamingMetadata(
                JSON.parse(event.data),
            );
        };

        return this;
    }

    /**
     * Close websocket connection and track final events.
     *
     * @returns {this}
     */
    closeStreamingServerWebsocketConnection () {
        if (this.hasStreamingServerWebsocketConnection()) {
            this.logger.log('Closing streaming server websocket connection');
            this.streamingServerWebsocket.close();
        }

        this.streamingServerWebsocket = undefined;

        return this;
    }

    /**
     * Determine whether a websocket connection to the stream server exists.
     *
     * @returns {Boolean}
     */
    hasStreamingServerWebsocketConnection () {
        return this.streamingServerWebsocket instanceof WebSocket &&
                this.streamingServerWebsocket.readyState === WebSocket.OPEN;
    }

    /**
     * Returns the websocket URL.
     *
     * @returns {String}
     */
    createWebsocketStreamingServerUrl () {
        if (!this.audioElement.src) {
            throw new Error('No media stream URL available');
        }

        const queryParams = new URLSearchParams({
            includecoverdata: true,
            includecompanionbannerdata: true,
        }).toString();

        const { host } = new URL(this.audioElement.src);

        return `wss://${host}/wstitleupdate?${queryParams}`;
    }

    /**
     * Create a media session metadata object for the received message.
     *
     * @param {{
     *   album: string,
     *   artist: string,
     *   title: string,
     *   artwork: ReadonlyArray<MediaImage>
     * }} metadata
     *
     * @returns {this}
     */
    createMediaSession (metadata) {
        if (window.nativeJsBridge.isWebview) {
            return this;
        }

        this.logger.log('Creating mediaSession', metadata);

        if ('mediaSession' in navigator) {
            const mediaMetadata = new MediaMetadata(metadata);

            this.logger.log('Created mediaSession metadata', mediaMetadata);
            navigator.mediaSession.metadata = mediaMetadata;
        } else {
            this.logger.log('Cannot create mediaSession: not supported in this client');
        }

        return this;
    }

    /**
     * Generate the media session artworks for the given cover/logo `metadata`.
     *
     * @param {{
     *   logo?: Channel['logo'],
     *   cover?: Cover,
     *   master_id?: string,
     *   cover_url?: string,
     *   cover_data?: string,
     * }} metadata
     *
     * @returns {MediaImage[]}
     */
    createMediaSessionArtwork (metadata) {
        /** @type {MediaImage[]} */
        const artwork = [];

        // 2023-10-17 - We only want fallback images or inline-base64 covers to reduce traffic
        // @see https://secure.helpscout.net/conversation/2392719005/15461/

        if (metadata.cover_data) {
            this.logger.log('Adding cover_data from websocket for mediasession artwork');
            artwork.push({
                type: 'image/jpeg',
                src: metadata.cover_data,
            });
        }

        if (this.fallbackMetadata.cover) {
            this.logger.log('Adding fallback cover for mediasession artwork');
            artwork.push({
                src: this.fallbackMetadata.cover,
                type: this.fallbackMetadata.cover.includes('.webp') ? 'image/webp' : 'image/jpeg',
            });
        }

        if (artwork.length === 0) {
            this.logger.log('Cannot create mediasession artwork: no available image found');
        }

        // if (metadata.cover) {
        //     artwork.push(
        //         ...metadata.cover.sizes.flatMap(size => {
        //             return Object.values(metadata.cover.extensions).map(extension => {
        //                 return {
        //                     sizes: size,
        //                     types: `image/${extension}`,
        //                     src: `${metadata.cover.baseurl}${size}/${metadata.cover.filename}${extension}`,
        //                 };
        //             });
        //         }),
        //     );
        // } else if (metadata.master_id) {
        //     // covers are available in sizes: 200x200, 300x300, 600x600
        //     ['200x200', '300x300', '600x600'].forEach(size => {
        //         artwork.push({
        //             sizes: size,
        //             type: 'image/jpeg',
        //             src: `/cover/${size}/${metadata.master_id}.jpg`,
        //         });
        //     });
        // } else if (metadata.logo) {
        //     artwork.push(
        //         ...metadata.logo.map(logo => {
        //             const parts = logo.url.split('.');
        //             const extension = parts.pop();

        //             return {
        //                 src: logo.url,
        //                 sizes: logo.size,
        //                 types: `image/${extension}`,
        //             };
        //         }),
        //     );
        // } else if (metadata.cover_data || metadata.cover_url) {
        //     artwork.push({
        //         type: 'image/jpeg',
        //         src: metadata.cover_data || metadata.cover_url,
        //     });
        // } else if (this.fallbackMetadata.cover) {
        //     artwork.push({
        //         src: this.fallbackMetadata.cover,
        //     });
        // } else {
        //     this.logger.log('Cannot create media session artwork: no available image found');
        // }

        return artwork;
    }

    /**
     * Returns the reference to the "playbar" HTML element.
     *
     * @returns {HTMLButtonElement}
     */
    playerDomElement () {
        const player = document.querySelector('.c-player');

        if (!player) {
            throw new Error('Cannot find an HTML element using the "c-player" CSS class.');
        }

        return player;
    }

    /**
     * Determine whether the DOM contains a player element.
     *
     * @returns {Boolean}
     */
    hasPlayerDomElement () {
        try {
            this.playerDomElement();
            return true;
        } catch (error) {
            return false;
        }
    }

    /**
     * Returns the reference to the "change stream" HTML wrapper <div> element.
     *
     * @returns {HTMLButtonElement}
     */
    changeStreamWrapper () {
        const wrapper = document.querySelector('[data-abywebplayer-change-stream]');

        if (!wrapper) {
            throw new Error('Cannot find wrapper for the "change stream" button in the playbar.');
        }

        return wrapper;
    }

    /**
     * Returns the reference to the airplay/chromecast HTML wrapper <div> element.
     *
     * @returns {HTMLButtonElement}
     */
    airplayWrapper () {
        const wrapper = document.querySelector('[data-abywebplayer-airplay]');

        if (!wrapper) {
            throw new Error('Cannot find wrapper for the "airplay button" in the playbar.');
        }

        return wrapper;
    }

    /**
     * Returns the reference to the airplay/chromecast HTML button element.
     *
     * @returns {HTMLButtonElement}
     */
    airplayButton () {
        const button = this.airplayWrapper().querySelector('[data-abywebplayer-airplay-button]');

        if (!button) {
            throw new Error('Cannot find airplay button in audio player.');
        }

        return button;
    }

    /**
     * Returns the reference to the "song search" HTML <a> element.
     *
     * @returns {HTMLAnchorElement}
     */
    songSearchLink () {
        const ahref = document.querySelector('[data-abywebplayer-search-songs]');

        if (!ahref) {
            throw new Error('Cannot find tag for the "song search" <a> tag in the playbar.');
        }

        return ahref;
    }

    /**
     * Returns the reference to the "stream detail page link" HTML <a> element.
     *
     * @returns {HTMLAnchorElement}
     */
    streamDetailPageLink () {
        const ahref = document.querySelector('[data-abywebplayer-detailpage-link]');

        if (!ahref) {
            throw new Error('Cannot find tag for the "stream detail page" <a> tag in the playbar.');
        }

        return ahref;
    }

    /**
     * @param {string} website
     * @returns {this}
     */
    updateChannelkeyInPlaybarLinks (website) {
        try {
            const [path, query = ''] = this.songSearchLink().href.split('?');
            const params = new URLSearchParams(query);

            this.mediaType !== 'channel'
                ? params.delete('channel')
                : params.set('channel', this.mediaId);

            this.songSearchLink().href = params.toString().length > 0
                ? `${path}?${params.toString()}`
                : path;

            this.streamDetailPageLink().href = website;
        } catch (error) {
            // one of the UI elements (song search links, stream details page link) is not present in the DOM
        }

        return this;
    }

    /**
     * Restore a previously saved channel data from local storage.
     *
     * @private
     */
    async findMediaToPlay () {
        const { channelkey } = Object.fromEntries(new URLSearchParams(window.location.search));

        if (channelkey) {
            return await this.initializeMedia('channel', channelkey);
        }

        if (await this.restoreAndStartPreviouslyPlayedStream()) {
            return;
        }

        await this.initializeMedia('channel', this.config.channel.channelkey);

        this.navigationHandler.on('render', () => {
            const condition = !this.isPlaying() &&
                window.antenne.config.channel.channelkey !== this.mediaId &&
                this.mediaType === 'channel';

            this.logger.log('checking channel config for change', {
                resource_config_channelkey: window.antenne.config.channel.channelkey,
                previous_mediaId: this.mediaId,
                will_switch: condition,
            });

            // Switch initialized channel, in case resource as a different channel in config-object
            if (condition) {
                this.initializeMedia('channel', window.antenne.config.channel.channelkey);
            }
        });
    }

    /**
     * Print the (possibly) provided media information in the playbar (title, artist, cover).
     *
     * @param {MediaType} type
     * @param {MediaId} mediaId
     * @param {MediaMetadata} metadata
     */
    async initializeMedia (mediaType, mediaId, metadata = {}) {
        this.logger.log('Initializing media', { type: mediaType, mediaId, metadata });

        if (!mediaType || !mediaId) {
            throw new Error('Empty media type or ID. Not initializing the player.');
        }

        this.mediaType = mediaType;
        this.mediaId = mediaId;
        this.mediaMetadata = metadata;

        try {
            this.playbarPlayButton().dataset.playType = mediaType;
            this.playbarPlayButton().dataset.play = mediaId;

            if (Object.keys(metadata).length > 0) {
                this.playbarPlayButton().dataset.metadata = JSON.stringify(metadata);
            }
        } catch (error) {
            // no playbar play button
        }

        if (this.mediaType === 'channel') {
            try {
                this.songSearchLink().classList.remove('u-hide');
                this.changeStreamWrapper().classList.remove('u-hide');
                this.streamDetailPageLink().classList.remove('u-hide');
            } catch (error) {
                // (one of the) elements not present in the DOM
            }

            const channelConfig = await this.channelConfigByChannelKey(mediaId);

            this
                .updateChannelkeyInPlaybarLinks(channelConfig.website)
                .fillFallbackMetadata(channelConfig)
                .adjustBookmarkLikeIconForCurrentlyPlayingMedia();

            const metadata = this.metadataService.getByMountpoint(channelConfig.stream.mountpoint);

            if (metadata) {
                await this.handleStreamingMetadata(
                    this.transformMetadataToStreamingMessage(metadata),
                );

                return this.createMediaSession({
                    album: channelConfig.title,
                    title: metadata.title,
                    artist: metadata.artist,
                    artwork: this.createMediaSessionArtwork({
                        cover: metadata.cover,
                        master_id: metadata.masterid,
                    }),
                });
            }

            this.logger.log('Cannot update player UI from HTTP metadata. Using fallback data', { metadata });
        } else {
            try {
                this.songSearchLink().classList.add('u-hide');
                this.changeStreamWrapper().classList.add('u-hide');
                this.streamDetailPageLink().classList.add('u-hide');
            } catch (error) {
                // (one of the) elements not present in the DOM
            }
        }

        this
            .fillFallbackMetadata({
                title: metadata.title,
                artist: metadata.artist,
                cover: metadata.cover,
            })
            .updateUi(metadata)
            .createMediaSession({
                album: this.config.station.name || '',
                title: metadata.title,
                artist: metadata.artist,
                artwork: this.createMediaSessionArtwork({
                    cover_url: metadata.cover,
                }),
            })
            .adjustBookmarkLikeIconForCurrentlyPlayingMedia();
    }

    /**
     * Ensure that a consent exists when playing an external stream URL.
     *
     * @param {string[]}
     *
     * @returns {this}
     */
    async ensureConsent (acceptedConsentTemplateIds = []) {
        const streamConfig = await this.getStreamConfig();

        /** @type {Array<{ templateId: string }> } */
        const requiredConsents = streamConfig.required_consents || [];

        const templateIdsRequiringConsent = []
            .concat(requiredConsents)
            .map(requiredConsent => requiredConsent.templateId)
            .filter(Boolean)
            .filter(templateId => {
                return !acceptedConsentTemplateIds.includes(templateId);
            });

        this.logger.log(`Ensuring consent for media type "${this.mediaType}"`, { templateIdsRequiringConsent });

        for (const templateId of templateIdsRequiringConsent) {
            const hasConsent = await this.commonMethods.hasConsentForTemplateId(templateId);

            if (!hasConsent) {
                throw new ConsentMissingError(`Missing consent for template "${templateId}"`, {
                    templateId,
                    acceptedTemplateIds: acceptedConsentTemplateIds,
                });
            }
        }

        this.logger.log('All required consents given to play media', {
            mediaType: this.mediaType,
            mediaId: this.mediaId,
        });
    }

    /**
     * Request a consent from the user for the given `templateId`.
     *
     * @param {string} templateId
     * @param {string[]} acceptedTemplateIds
     *
     * @returns {Promise<void>}
     */
    async requestConsentForTemplateId (templateId, acceptedTemplateIds) {
        this.logger.log('Requesting consent for service', { templateId, acceptedTemplateIds });

        try {
            this.ensureFetchIsAvailable();

            const response = await fetch(`/consent-dialog/${templateId}`, {
                method: 'POST',
                body: JSON.stringify({
                    mediaType: this.mediaType,
                    mediaId: this.mediaId,
                    acceptedTemplateIds,
                }),
            });

            if (response.status === 200) {
                const html = await response.text();

                this.eventEmitter.emit('dialog.renderAndShow', {
                    title: 'Dein Consent wird benötigt',
                    content: html,
                    cssContentClasses: 'c-dialog__content--whitebg',
                });
            } else {
                throw new Error(`Received invalid consent dialog response (HTTP status ${response.status})`);
            }
        } catch (error) {
            this.logger.error('Failed to fetch HTML content for consent dialog', error);
        }
    }

    /**
     * @returns {HTMLButtonElement}
     */
    playbarPlayButton () {
        const button = document.querySelector('[data-abywebplayer-play]');

        if (!button) {
            throw new Error('Cannot find playbar play button via "data-abywebplayer-play" in document');
        }

        return button;
    }

    /**
     * Returns the channel configuration for the given `channelkey`.
     *
     * @param {String} channelkey
     *
     * @returns {Channel}
     */
    async channelConfigByChannelKey (channelkey) {
        this.logger.log(`Retrieving channel config for channelkey "${channelkey}"`);

        // if the html-inlined channel is requested, return it directly, without api
        if (this.config.channel.channelkey === channelkey) {
            return this.config.channel;
        }

        try {
            const channel = await this.channelService.getChannel(channelkey);
            return channel;
        } catch (error) {
            const node = document.querySelector(
                `[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"][data-play-stream]`,
            );

            if (node && node.dataset.playStream) {
                const channel = {
                    channelkey: this.mediaId,
                    stream: JSON.parse(node.dataset.playStream),
                };
                this.logger.warn('Channel only found via markup', channel);
                return channel;
            }
            throw error;
        }
    }

    /**
     * Fill the fallback metadata object with details from the `channel` data.
     *
     * @param {Channel} channel
     *
     * @returns {this}
     */
    fillFallbackMetadata (channel = {}) {
        if (channel.title && channel.claim) {
            this.setPlayerUiFallbacks({
                title: channel.title,
                artist: channel.claim,
                cover: this.createCoverUrl(channel.logo),
            });
        }

        return this;
    }

    /**
     * @returns {this}
     */
    setPlayerUiFallbacks ({ title, artist, cover } = {}) {
        this.fallbackMetadata.title = title;
        this.fallbackMetadata.artist = artist;
        this.fallbackMetadata.cover = cover;

        return this;
    }

    /**
     * Set the title, artist, and cover in the playbar.
     *
     * @param {Metadata} metadata
     *
     * @returns {this}
     */
    updateUi (metadata = {}) {
        if (!metadata.title) {
            metadata.title = this.fallbackMetadata.title;
        }

        if (!metadata.artist) {
            metadata.artist = this.fallbackMetadata.artist;
        }

        if (!metadata.cover) {
            metadata.cover = this.fallbackMetadata.cover;
        } else {
            metadata.cover = this.createCoverUrl(metadata.cover);
        }

        this.logger.info('Updating player UI', { metadata });

        return this
            .setTitleInPlayerUi(metadata.title)
            .setArtistInPlayerUi(metadata.artist)
            .setCoverImageInPlayerUi(metadata.cover);
    }

    /**
     * Returns a URL for the given `coverImage`. The provided coverImage argument
     * can be of different types, depending on the source:
     *   - metadata API
     *   - metadata message from streaming server websocket
     *   - channel logos from channel configuration
     *
     * @param {string | Channel['logo'] | Cover} coverImage
     *
     * @returns {String}
     */
    createCoverUrl (coverImage) {
        if (!coverImage) {
            return this.fallbackMetadata.cover;
        }

        // this happens for websocket messages where cover images can be Base64 encoded or passed as the
        if (typeof coverImage === 'string') {
            return coverImage;
        }

        // type `Cover`: used by metadata items
        if (typeof coverImage === 'object' && !Array.isArray(coverImage)) {
            // translate `Cover` images to `Image` URLs

            if (coverImage.baseurl) {
                coverImage = coverImage.sizes.flatMap(size => {
                    return Object.values(coverImage.extensions).map(extension => {
                        return {
                            size,
                            url: `${coverImage.baseurl}${size}/${coverImage.filename}${extension}`,
                        };
                    });
                });
            }
        }

        // array of type `Image` (using `url` and `size`): used in channel configs
        if (Array.isArray(coverImage)) {
            const imageUrl =
            this.commonMethods.findMatchingImage(coverImage, '128x128', 'webp') ||
            this.commonMethods.findMatchingImage(coverImage, '128x128');

            if (imageUrl) {
                return imageUrl;
            }

            const svg = coverImage.find(image => String(image.url).endsWith('.svg'));

            if (svg) {
                return svg.url;
            }
        }

        return '';
    }

    /**
     * Set the given `title` in the audio player UI.
     *
     * @param {String} title
     *
     * @returns {this}
     */
    setTitleInPlayerUi (title) {
        const element = document.querySelector('[data-abywebplayer-currenttitle]');

        if (!element) {
            this.logger.warn('Cannot find element with "data-abywebplayer-currenttitle" attribute. Cannot set title');
        } else {
            element.textContent = title;
        }

        return this;
    }

    /**
     * Set the given `artist` in the audio player UI.
     *
     * @param {String} artist
     *
     * @returns {this}
     */
    setArtistInPlayerUi (artist) {
        const element = document.querySelector('[data-abywebplayer-currentartist]');

        if (!element) {
            this.logger.warn('Cannot find element with "data-abywebplayer-currentartist" attribute. Cannot set artist');
        } else {
            element.textContent = artist;
        }

        return this;
    }

    /**
     * Set the given `imageUrl` in the audio player UI for the cover image.
     *
     * @param {String} imageUrl
     *
     * @returns {this}
     */
    setCoverImageInPlayerUi (imageUrl) {
        if (!imageUrl) {
            this.logger.warn('Received invalid cover image URL. Ignoring it', { imageUrl });

            return this;
        }

        /** @type {HTMLImageElement | null} */
        const img = document.querySelector('[data-abywebplayer-image]');

        if (!img) {
            this.logger.warn('Cannot find element with "data-abywebplayer-image" attribute. Cannot set cover image');
        } else {
            img.src = imageUrl;

            /**
             * The onerror handler gets called when the provided `img.src` fails to load.
             * For example, this happens when song covers are not available. For such
             * cases we fallback to the channel cover. If we can’t load the channel
             * cover either, we’re defaulting to a transparent 1 pixel gif cover.
             */
            let hasImageLoadingFailed = false;
            img.onerror = () => {
                if (!hasImageLoadingFailed && this.mediaType === 'channel') {
                    this.logger.warn('Cover image is not available. Showing channel cover as fallback', {
                        imageUrl,
                        channelCoverUrl: this.fallbackMetadata.cover,
                    });

                    img.src = this.fallbackMetadata.cover;
                } else {
                    this.logger.warn('Channel cover is also not available. Showing empty placeholder');

                    // transparent 1x1 gif pixel
                    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
                }

                hasImageLoadingFailed = true;
            };
        }

        return this;
    }

    async togglePlayPause (type, mediaId, metadata = {}) {
        if (this.isPlaying() && this.mediaId === mediaId && this.mediaType === type) {
            await this.pause(type, mediaId);
        } else {
            await this.play(type, mediaId, metadata);
        }
    }

    /**
     * Determine whether the client is using iOS 15 on an iPhone or iPad.
     *
     * @returns {boolean}
     *
     * @private
     */
    isIos15onIphoneOrIpad () {
        const iosUserAgentMatches = /(iPhone|iPad) OS ([1-9]*)/g.exec(window.navigator.userAgent) || [];
        const iosVersion = iosUserAgentMatches.length >= 2
            ? Number(iosUserAgentMatches[2])
            : 0;

        return iosVersion === 15;
    }

    /**
     * There’s an issue with audio in iOS 15 which causes mobile Chrome and mobile
     * Safari to not play audio. This hint is a workaround so that users know
     * we can’t play on old devices. Instead, we’re showing a modal dialog.
     *
     * @returns {boolean}
     *
     * @private
     */
    showIos15AppDownloadHint () {
        // eslint-disable-next-line max-len
        this.logger.warn('Detected iPhone/iPad running iOS 15. Showing a modal dialog: we kindly ask users to download and use the app to play audio, because we can’t play on iOS 15 (because of a browser permission bug)');

        this.eventEmitter.emit('dialog.renderAndShow', {
            title: 'Das Webradio wird unter iOS 15 leider nicht mehr unterstützt',
            // eslint-disable-next-line max-len
            content: `
                <h3 class="o-headline--size5">Das Webradio wird unter iOS 15 leider nicht mehr unterstützt</h3>
                <p>
                    Du kannst alternativ die kostenlose <strong>Radio-App von ${this.config.station.name}</strong>
                    aus dem App Store installieren. Diese läuft auch weiterhin unter iOS 15.
                </p>`,
            cssContentClasses: 'u-extra-medium-padding',
        });
    }

    /**
     * Start playing the given media identified by `type` and `mediaId`.
     *
     * @param {MediaType} mediaType
     * @param {MediaId} mediaId
     * @param {MediaMetadata} metadata
     * @param {AudioPlayerPlayOptions} options
     */
    async play (mediaType, mediaId, metadata = {}, options = {}) {
        if (this.isIos15onIphoneOrIpad()) {
            return this.showIos15AppDownloadHint();
        }

        console.time('starting audio took');

        this.logger.log('Should play media', { mediaType, mediaId, metadata });

        this.eventEmitter.emit('audio:play', {
            mediaType,
            mediaId,
        });

        if (this.hasPlayerDomElement()) {
            this.playerDomElement().classList.remove('has-error');
        }

        if (mediaType === 'xtra') {
            this.saveCurrenlyPlayingStream();
        }

        await this.pause(this.mediaType, this.mediaId);

        if (!this.audioElement) {
            throw new Error('Cannot start playing because player is not initialized.');
        }

        if (this.isStartingAudio) {
            this.logger.log(`Already starting Audio player for "${this.mediaType}".`, {
                type: this.mediaType,
                mediaId: this.mediaId,
            });

            return;
        }

        this.isStartingAudio = true;
        const isResuming = this.audioElement.src &&
                            this.audioElement.src !== this.defaultAudioPlayerSrc &&
                            this.mediaId === mediaId &&
                            this.mediaType === mediaType &&
                            this.mediaType !== 'channel' &&
                            this.mediaType !== 'xtra';

        try {
            await this.initializeMedia(mediaType, mediaId, metadata);
        } catch (error) {
            this.logger.error(`Cannot play media: ${error.message}`, { error, mediaType, mediaId, metadata });
            this.isStartingAudio = false;

            return;
        }

        const mediaPlayButtons = document.querySelectorAll(`[data-play-type="${mediaType}"][data-play="${mediaId}"]`);

        mediaPlayButtons.forEach(button => {
            button.classList.add('is-loading');
        });

        this.logger.log('Starting audio player');

        try {
            if (isResuming) {
                this.logger.log('Resuming media stream.');
            } else {
                this.logger.log('Resolving (redirected) stream URL');
                const streamUrl = await this.resolveRedirectedStreamUrl(options);
                this.logger.log(`Should start playing audio stream: ${streamUrl}`);

                this.audioElement.src = streamUrl;
            }

            /**
             * Putting the "ensure consent" down here makes sure we’re resolving the
             * stream URL before asking for consents. We already assigned the media
             * data and the `isResuming` check above would wrongfully return true.
             */
            await this.ensureConsent(options.acceptedConsentTemplateIds);

            if (window.nativeJsBridge.isWebview) {
                await this.notifyNativeAboutAudioPlay();
                this.checkPlaybuttonsState();
            } else {
                this.isPlayingOnNativeApp = false;
                await this.audioElement.play();

                this.trackStreamStartEvent();

                this
                    .createWebsocketConnectionToStreamingServer()
                    .showOrHideProgressBarForTrack()
                    .adjustBookmarkLikeIconForCurrentlyPlayingMedia();
            }
        } catch (error) {
            if (error instanceof ConsentMissingError) {
                this.logger.warn(`${error.message}`, { error });
                await this.requestConsentForTemplateId(error.templateId, error.acceptedTemplateIds);
            } else if (error.name === 'NotAllowedError') {
                this.logger.warn('(Auto-)Play is not allowed', { error });
                this.audioElement.dispatchEvent(new Event('playblocked'));
            } else {
                this.logger.error('Failed to start media', { error });
                this.audioElement.dispatchEvent(new Event('playerror'));
            }
        }

        mediaPlayButtons.forEach(button => {
            button.classList.remove('is-loading');
        });

        console.timeEnd('starting audio took');

        this.isStartingAudio = false;
    }

    /**
     * Send an `audio.play` event to the native app.
     */
    async notifyNativeAboutAudioPlay () {
        try {
            await window.nativeJsBridge.callHandlerIfWebview('audio.play', {
                type: this.mediaType,
                mediaId: this.mediaId,
                metadata: this.mediaMetadata,
                stream: await this.getStreamConfig(),
            });
            this.isPlayingOnNativeApp = true;
        } catch (error) {
            this.logger.error('Received error while sending "audio.play" to native app', error);
        }
    }

    /**
     * Send a `media.stop` event to the native app.
     */
    async notifyNativeAboutMediaStop () {
        try {
            await window.nativeJsBridge.callHandlerIfWebview('media.stop', {});
            this.isPlayingOnNativeApp = false;
        } catch (error) {
            this.logger.error('Received error while sending "media.stop" to native app', error);
        }
    }

    /**
     * Returns the stream config for the focussed media item. Throws an error
     * if a stream config is missing.
     *
     * @returns {Promise<Channel['stream']>}
     */
    async getStreamConfig () {
        if (this.mediaType === 'channel') {
            const channelConfig = await this.channelConfigByChannelKey(this.mediaId);

            if (channelConfig.stream) {
                return channelConfig.stream;
            }
        }

        // Mediathek
        if (this.mediaType === 'digas') {
            const node = document.querySelector(
                `[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`,
            );

            if (node && node.dataset.playStream) {
                return JSON.parse(node.dataset.playStream);
            }

            return {
                mp3: `https://${this.config.streamhost}/digas/${this.config.station.stationkey}/${this.mediaId}/stream`,
            };
        }

        if (
            this.mediaType === 'podigee' ||
            this.mediaType === 'art19' ||
            this.mediaType === 'omnycontent' ||
            this.mediaType === 'julep'
        ) {
            const node = document.querySelector(
                `[data-play-type="${this.mediaType}"][data-play="${this.mediaId}"]`,
            );

            if (node && node.dataset.playStream) {
                return JSON.parse(node.dataset.playStream);
            }

            // eslint-disable-next-line max-len
            throw new Error('Cannot find podigee stream config: "data-play-stream" attribute is missing on play button', {
                mediaType: this.mediaType,
                mediaId: this.mediaId,
            });
        }

        if (this.mediaType === 'song') {
            return {
                mp3: `https://${window.antenne.config.streamhost}/digas/nethookbase/${this.mediaId}/stream`,
            };
        }

        if (this.mediaType === 'xtra') {
            const current = await this.xtraService.getCurrent();

            if (current && current.stream) {
                return current.stream;
            }

            throw new Error('Cannot play xtra: missing stream ');
        }

        throw new Error('Cannot find stream config for media', {
            mediaType: this.mediaType,
            mediaId: this.mediaId,
        });
    }

    /**
     * Prepare final, resolved stream URL with all required query parameters for the streaming server.
     *
     * @param {AudioPlayerPlayOptions} options
     *
     * @returns {Promise<String>}
     */
    async resolveRedirectedStreamUrl (options = {}) {
        const streamConfig = await this.getStreamConfig();

        if (this.mediaType !== 'channel' && this.mediaType !== 'xtra') {
            return streamConfig.mp3;
        }

        try {
            const streamUrl = await Promise.race([
                this.commonMethods.timeoutAfterSeconds(4),
                this.retrieveRedirectedUrl(streamConfig),
            ]);

            const queryParams = await this.getEncodedStreamUrlParameters(streamConfig, options);

            return `${streamUrl}?${queryParams}`;
        } catch (error) {
            this.logger.warn('Failed to resolve (redirected) stream URL:', error);

            return streamConfig.mp3;
        }
    }

    /**
     * Returns the resolved stream URL following redirects.
     *
     * @param {Channel['stream']} streamConfig
     *
     * @returns {String}
     */
    async retrieveRedirectedUrl (streamConfig) {
        this.ensureFetchIsAvailable();

        const response = await fetch(streamConfig.mp3, { method: 'HEAD' });

        if (response.redirected) {
            this.logger.log(`Redirected url detected: ${response.url}`);
        }

        return response.url;
    }

    /**
     * Ensure the Fetch API is available on the global window object.
     */
    ensureFetchIsAvailable () {
        if ('fetch' in window) {
            return;
        }

        this.logger.error('Fetch API not found. Please try including a polyfill');
        throw new Error('fetch not supported');
    }

    /**
     * Returns the URL-encoded audio stream parameters to be used as a query string.
     *
     * @param {Channel['stream']} streamConfig
     * @param {AudioPlayerPlayOptions} audioPlayerPlayOptions
     *
     * @returns {string}
     */
    async getEncodedStreamUrlParameters (streamConfig, audioPlayerPlayOptions = {}) {
        /** @type {Array<{ type: string, identifier: string }>} identifiers */
        let identifiers = [];

        try {
            identifiers = await Promise.race([
                this.commonMethods.timeoutAfterSeconds(3),
                this.trackingService.getAllAvailableIdentifiers(),
            ]);
        } catch (error) {
            this.logger.error('Error getting identifiers', error);
            // ignore error and continue
        }

        const adswizzListenerId = identifiers.find(obj => obj.type === 'adswizz-listenerid') || {};

        const urlParams = {
            'aw_0_1st.playerid': this.config.playerid,
            'aw_0_1st.listenerid': adswizzListenerId.identifier,
            'aw_0_1st.skey': Date.now(),

            'aw_0_req.userConsentV2': await this.commonMethods.getTcString(),

            // See mail from Jan from 2023-12-04: Webradio Stream URL aw_0_req.iab= ausbauen => Vermarktung gestört
            // 'aw_0_req.iab': Array.from((streamConfig.iab && streamConfig.iab.categories) || []).join(','),

            companionAds: false, // if adswizz consent, this is set to true below
            companion_zone_alias: (streamConfig.adswizz && streamConfig.adswizz.companion_zone_alias),
        };

        /**
         * When starting a channel after playing an Xtra, we always want users to start
         * the new channel stream with a section of music. We don’t want users start
         * listening to news/ads/weather. The `musicstart` query parameter tells
         * the streaming server to enforce music when starting the new stream.
         */
        if (audioPlayerPlayOptions.hasPlayedXtraBefore && this.mediaType === 'channel') {
            urlParams.musicstart = true;
        }

        /**
         * Check for query params for ad campaign params.
         * Add those params to our urlParams to be sent on to the Stream Url.
         */
        const params = new URLSearchParams(window.location.search.substring(1));

        if (params.get('wid') && !isNaN(parseInt(params.get('wid')))) {
            urlParams.wid = parseInt(params.get('wid'));
        }

        if (params.get('campaignSource')) {
            urlParams.campaignSource = params.get('campaignSource').replace(/[^a-zA-Z0-9]/g, '');
        }

        if (this.commonMethods.allConsentsAccepted()) {
            this.logger.log('All consents given');
            urlParams.companionAds = true;
        } else {
            this.logger.warn('Not all consents given. No companion ads enabled.');
        }

        if (params.has('streamserver_query')) {
            const streamserverQuery = decodeURIComponent(params.get('streamserver_query'));

            new URLSearchParams(streamserverQuery).forEach((value, key) => {
                urlParams[key] = value;
            });
        }

        this.logger.log('Created stream URL parameter object (removing nullish values before sending):', urlParams);
        return this.urlEncode(urlParams);
    }

    /**
     * URL-encode the object of URL `parameters` and remove `null` and `undefined` values.
     *
     * @param {Object} parameters
     *
     * @returns {String}
     */
    urlEncode (parameters) {
        return Object
            .entries(parameters)
            .filter(([_key, value]) => value != null)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');
    }

    /**
     * Show the progress bar if the currently played track is seekable, otherwise hide it.
     *
     * @returns {this}
     */
    showOrHideProgressBarForTrack () {
        if (!this.hasPlayerDomElement()) {
            return this;
        }

        if (!this.hasError() && this.playsSeekableTrack()) {
            this.logger.log('Detected seekable track. Showing progress bar');
            this.progressbar.init();

            this.playerDomElement().classList.add('has-progressbar');

            this.progressbar.repaint();

            return this;
        }

        this.playerDomElement().classList.remove('has-progressbar');

        return this;
    }

    /**
     * Determine whether the player is in an error state.
     *
     * @returns {Boolean}
     */
    hasError () {
        return this.hasPlayerDomElement() && this.playerDomElement().classList.contains('has-error');
    }

    /**
     * Determine whether the currently played track is seekable.
     *
     * @returns {Boolean}
     */
    playsSeekableTrack () {
        const isFinite = Number.isFinite(this.audioElement.duration);

        if (this.mediaMetadata.seekable == null) {
            return isFinite;
        }

        return isFinite && this.mediaMetadata.seekable;
    }

    /**
     * @param {Number} seconds
     *
     * @returns {this}
     */
    assignProgressInSeconds (seconds) {
        this.audioElement.currentTime = seconds;

        return this;
    }

    /**
     * @param {StreamingMetadataMessage|undefined} message
     * @returns {this}
     */
    adjustBookmarkLikeIconForCurrentlyPlayingMedia (message) {
        message = message || {};

        this.logger.log('Adjusting favorite/like button for message', { message });
        const bookmarkButton = document.querySelector('[data-abywebplayer-favorite]');

        if (!bookmarkButton) {
            this.logger.warn('Favorite button is not available, not able adjust it to a correct state.');
            return this;
        }

        const likeButton = document.querySelector('[data-abywebplayer-like]');

        if (!likeButton) {
            this.logger.warn('Like button is not available, not able adjust it to a correct state.');
            return this;
        }

        if (Object.keys(message).length === 0) {
            if (this.mediaType === 'channel') {
                bookmarkButton.setAttribute('disabled', true);
                bookmarkButton.classList.add('u-disabled');
                bookmarkButton.classList.remove('u-hide');

                likeButton.classList.add('u-hide');

                const bookmarkData = { category: 'channels', content_id: this.mediaId };
                bookmarkButton.dataset.bookmark = JSON.stringify(bookmarkData);

                return this.adjustIconInFavoriteButton(bookmarkButton);
            }
        }

        if (this.mediaType === 'channel' && message.class === 'music' && message.master_id) {
            bookmarkButton.classList.remove('u-hide', 'u-disabled');
            bookmarkButton.removeAttribute('disabled');

            likeButton.classList.add('u-hide');

            bookmarkButton.dataset.bookmark = JSON.stringify({ category: 'songs', content_id: message.master_id });

            return this.adjustIconInFavoriteButton(bookmarkButton);
        }

        if (this.mediaType === 'song') {
            bookmarkButton.classList.remove('u-hide', 'u-disabled');
            bookmarkButton.removeAttribute('disabled');

            likeButton.classList.add('u-hide');

            bookmarkButton.dataset.bookmark = JSON.stringify({ category: 'songs', content_id: this.mediaId });

            return this.adjustIconInFavoriteButton(bookmarkButton);
        }

        if (
            this.mediaType === 'podigee' ||
            this.mediaType === 'art19' ||
            this.mediaType === 'omnycontent' ||
            this.mediaType === 'julep'
        ) {
            bookmarkButton.classList.add('u-hide');

            likeButton.classList.remove('u-hide', 'u-disabled');
            likeButton.removeAttribute('disabled');

            const like = { type: 'podcasts', identifier: this.mediaId };
            likeButton.dataset.like = JSON.stringify(like);

            return this.adjustIconInLikeButton(likeButton);
        }

        if (this.mediaType === 'digas' || this.mediaType === 'xtra') {
            this.logger.warn(
                `Favorite and like buttons are disabled because ${this.mediaType} content is not bookmark-/likeable.`,
            );

            bookmarkButton.setAttribute('disabled', true);
            bookmarkButton.classList.add('u-disabled');

            likeButton.setAttribute('disabled', true);
            likeButton.classList.add('u-disabled');

            return this;
        }

        this.logger.warn('Could not adjust favorite/like button for message', { message });

        return this;
    }

    /**
     * Adjust the icon in the favorite `button` to its correct state.
     *
     * @param {HTMLButtonElement} button
     *
     * @returns {this}
     */
    adjustIconInFavoriteButton (button) {
        this.user.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                this.user.checkBookmarkButton(button);
            }
        });
        return this;
    }

    /**
     * Adjust the icon in the like `button` to its correct state.
     *
     * @param {HTMLButtonElement} button
     *
     * @returns {this}
     */
    adjustIconInLikeButton (button) {
        this.user.checkLikeButton(button);
        return this;
    }

    /**
     * Pause streaming audio on the player.
     *
     * @param {MediaType} type
     * @param {MediaId} mediaId
     */
    async pause (type = this.mediaType, mediaId = this.mediaId) {
        this.logger.log('Pausing audio stream');

        if (window.nativeJsBridge.isWebview) {
            await this.notifyNativeAboutMediaStop();
            this.checkPlaybuttonsState();
        }

        if (!this.audioElement) {
            throw new Error('Cannot pause playing because player is not initialized.');
        }

        if (!this.isPlaying()) {
            this.logger.log('Audio player already paused');
            return;
        }

        const playButtons = document.querySelectorAll(`[data-play-type="${type}"][data-play="${mediaId}"]`);

        playButtons.forEach(button => {
            button.classList.remove('is-playing');
            button.classList.add('is-loading');
        });

        if (this.playsSeekableTrack()) {
            this.previousTrackCurrentTimeInSeconds = this.audioElement.currentTime;
        } else {
            this.previousTrackCurrentTimeInSeconds = undefined;
        }

        if (this.mediaType === 'channel' || this.mediaType === 'xtra') {
            this.logger.log('Forcing a full stop with changed src for audioplayer');
            // Force a full stop and reset
            // eslint-disable-next-line max-len
            this.audioElement.src = this.defaultAudioPlayerSrc;
            this.audioElement.load();
        } else {
            this.audioElement.pause();
        }

        this.closeStreamingServerWebsocketConnection();
        this.progressbar.stop();

        playButtons.forEach(button => {
            button.classList.remove('is-loading');
        });

        this.logger.log('Audio player paused/stopped');
    }

    /**
     * Determine whether the audio player is currently playing.
     *
     * @returns {boolean}
     */
    isPlaying () {
        if (this.isPlayingOnNativeApp === true) {
            return true;
        }

        if (!this.audioElement) {
            return false;
        }

        return !this.audioElement.paused;
    }

    /**
     * Track the stream start event.
     *
     * @returns {this}
     */
    trackStreamStartEvent () {
        this.trackEvent('fix:stream:start', {
            identifier: this.mediaId,
            media_type: this.mediaType,
            type: 'audio',
            // playerid: 'webplayer',
        });

        // set timer for 60s for event tracking
        this.playbackTimer = setTimeout(() => {
            this.logger.log('60s Playback reached');
            this.trackEvent('fix:stream:60s_playback', {
                identifier: this.mediaId,
            });
        }, 60 * 1000);

        return this;
    }

    /**
     * Track the stream stop event.
     *
     * @returns {this}
     */
    trackStreamStopEvent () {
        // if (this.mediaId) {
        //     this.trackEvent('fix:stream:stop', {
        //         identifier: this.mediaId,
        //         type: 'audio',
        //         // playerid: 'webplayer',
        //     });
        // }

        clearTimeout(this.playbackTimer);

        return this;
    }

    /**
     * Track the `fix:media:start` event.
     *
     * @returns {this}
     */
    trackMediaStartEvent (occurredon = new Date().toISOString()) {
        /*
         * `fix:media:*` events are ignored for Google via TrackingService::quantyooEventToGoogleEvent()
        */

        // if (this.currentMetadataItem && !!this.currentMetadataItem.type) {
        //     this.trackEvent('fix:media:start', {
        //         ...this.currentMetadataItem,
        //         occurredon,
        //     });
        // }

        return this;
    }

    /**
     * Track the `fix:media:cancel` event.
     *
     * @returns {this}
     */
    trackMediaCancelEvent (occurredon = new Date().toISOString()) {
        // if (this.currentMetadataItem && !!this.currentMetadataItem.type) {
        //     this.trackEvent('fix:media:cancel', {
        //         ...this.currentMetadataItem,
        //         occurredon,
        //     });
        // }

        return this;
    }

    /**
     * Track the `fix:media:complete` event.
     *
     * @returns {this}
     */
    trackMediaCompleteEvent (occurredon = new Date().toISOString()) {
        // if (this.currentMetadataItem && !!this.currentMetadataItem.type) {
        //     this.trackEvent('fix:media:complete', {
        //         ...this.currentMetadataItem,
        //         occurredon,
        //     });
        // }

        return this;
    }

    /**
     * Track an event for the Quantyoo datastream.
     *
     * @param {string} type Streamtype
     * @param {object} data Payload object
     *
     * @returns {this}
     */
    trackEvent (type, data) {
        if (data.cover) {
            delete data.cover;
        }
        this.logger.log('Tracking event', { type, data });

        try {
            this.trackingService.track(type, data);
        } catch (error) {
            this.logger.error('Failed to track event', { type, data, error });
        }

        return this;
    }
}
