/**
 * User Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('./AntenneConfig').default} AntenneConfig
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./TrackingService').default} TrackingService
 * @typedef {import('./BookmarkService').default} BookmarkService
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('../antenne-frontend').DataLikeAttribute} DataLikeAttribute
 * @typedef {import('../antenne-frontend').BookmarkDataAttribute} BookmarkDataAttribute
 */

import ClassLogger from 'ClassLogger';
import { ApiError } from './ApiError';
import { ApiClient } from './ApiClient';

class User {
    getClassName () { return 'User'; }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     * @param {EventEmitter} eventEmitter
     * @param {Dialog} dialogService
     * @param {TrackingService} trackingService
     * @param {BookmarkService} bookmarkService
     */
    constructor (commonMethods, navigationHandler, eventEmitter, dialogService, trackingService, bookmarkService) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;
        this.eventEmitter = eventEmitter;
        this.dialogService = dialogService;
        this.trackingService = trackingService;
        this.bookmarkService = bookmarkService;

        /** @private */
        this.apiClient = new ApiClient();

        this.contextkey = window.antenne.config.contextkey;
        this.publicCookieName = window.antenne.config.cookie || this.contextkey + '-user';
        this.likeStorageKey = 'user.likes';
        this.bookmarkStorageKey = 'user.bookmarks';

        this.isLoggedIn().then(isLoggedIn => this.logger.log('Checking if User is logged in.', { isLoggedIn }));

        this.nodesToHandle = {
            '[data-user="topnav-link"]': (topNavLinks) => this.handleTopNav(topNavLinks),
            '[data-userfield]': (fields) => this.handleUserField(fields),
            '[data-greeting]': (fields) => this.handleGreetingField(fields),
            '[data-like]': (buttons) => this.handleLikeButton(buttons),
            '[data-bookmark]': (buttons) => this.handleBookmarkButton(buttons),
            '[data-bookmarkwidget]': (elements) => this.handleBookmarkWidget(elements),
            '.c-form': (elements) => this.handleForms(elements),
        };

        /*
        this.observer = new MutationObserver((mutations, obs) => {
            this.logger.log('handling mutations');
            this.checkNodesAndHandle('MutationObserver');
        });
        this.observer.observe(document, {
            childList: true,
            subtree: true,
        });
        */
        this.navigationHandler
            .on('render', () => {
                this.checkNodesAndHandle('onRender');
                this.checkRegWallForPage();
            })
            .on('ready', () => {
                this.checkNodesAndHandle('isDomReady');
                if (this.observer) this.observer.disconnect();
                this.initRegWall();
            });

        this.eventEmitter.on('user:bookmarks:handleButtons', async () => {
            await this.checkNodesForSelector('[data-bookmark]', this.nodesToHandle['[data-bookmark]'], 'emitter');
        });

        window.openRegWall = (...args) => this.openRegWall(args);

        this.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn === false) {
                this.clearUserStorage();
            } else {
                this.bookmarkService.setUserId(this.user.id);
                this.trackingService.setUser(this.user);
            }
        });

        // Track login/logout events
        const initialQueryString = new URLSearchParams(document.location.search);
        if (initialQueryString.get('logout') === 'ok' && initialQueryString.get('i')) {
            this.trackingService.track('logout', {
                identifier: initialQueryString.get('i'),
            });
        }
        if (initialQueryString.get('login') === 'ok' && initialQueryString.get('i')) {
            this.commonMethods.hash(this.user.id, 'SHA-256').then((hashedId) => {
                this.trackingService.track('login', {
                    identifier: initialQueryString.get('i'),
                    method: 'ODP',
                    user_id: hashedId,
                });
            }).catch(() => {
                this.trackingService.track('login', {
                    identifier: initialQueryString.get('i'),
                    method: 'ODP',
                });
            });
        }
    }

    async checkRegWallForPage () {
        if (
            await this.isLoggedIn() === false &&
            window.antenne &&
            window.antenne.config &&
            window.antenne.config.regwall &&
            window.antenne.config.regwall.until > (Date.now() / 1000)
        ) {
            this.logger.log('opening regwall because page is still behind the regwall');
            this.openRegWall(false);
        }
    }

    async initRegWall () {
        await this.checkRegWallForPage();

        if (await this.isLoggedIn() === false) {
            this.logger.log('Adding global click handler for regwall');
            // Register click handler for regwall links
            document.addEventListener('click', (e) => {
                const target = e.target.closest('[data-regwalluntil]');

                if (target && target.dataset) {
                    if (parseInt(target.dataset.regwalluntil) > (Date.now() / 1000)) {
                        this.logger.log('RegWall target clicked', { target });
                        this.openRegWall();
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                }
            }, true);
            this.showRegWallLockIcons();
        }
    }

    /**
     * This method scans the page for all items with regwall data attributes.
     * In cards, the u-faux-block-link-overlay link has this information.
     * Adds a css state that changes needed styling.
     */
    showRegWallLockIcons () {
        const iconItems = document.querySelectorAll('[data-regwalluntil]');
        iconItems.forEach((item) => {
            const cardElement = item.closest('.c-card');
            if (cardElement) {
                cardElement.classList.add('has-regwall');
            }
        });
    }

    openRegWall (closeable = true) {
        if (!this.regwallObject) {
            const id = (Math.random() + 1).toString(36).substring(7);
            const dialogNode = this.commonMethods.markupToElement(
                window.antenne.templates.regwall(id),
            );
            document.body.append(dialogNode);
            this.regwallObject = this.dialogService.initDialogNode(dialogNode, closeable);
        }
        this.regwallObject.show();
    }

    async checkNodesAndHandle (caller) {
        for (const [selector, handler] of Object.entries(this.nodesToHandle)) {
            this.checkNodesForSelector(selector, handler, caller);
        }
    }

    async checkNodesForSelector (selector, handler, caller) {
        const nodes = document.querySelectorAll(selector);
        if (nodes.length > 0) {
            this.logger.log('Initializing ' + selector + ' from ' + caller);
            await handler(nodes);
        }
    }

    async handleTopNav (topNavLinks) {
        if (await this.isLoggedIn() !== true) {
            return;
        }
        // we only expect 1 item here
        const topNavLink = topNavLinks[0];
        if (topNavLink.classList.contains('is-initialized')) {
            return;
        }
        topNavLink.classList.add('is-initialized');
        this.logger.log('init topnav', topNavLink);
        if (await this.isLoggedIn()) {
            topNavLink.querySelector('.c-headeractions__icon--logged-in').classList.remove('u-hide');
            topNavLink.querySelector('.c-headeractions__icon--logged-out').classList.add('u-hide');
            topNavLink.removeAttribute('data-navhandlerlink');
        }
    }

    handleForms (elements) {
        elements.forEach(async (element) => {
            const loginWallElement = element.querySelector('[data-loginwall]');
            if (await this.isLoggedIn() === false && loginWallElement) {
                loginWallElement.classList.remove('u-hide');
            }
        });
    }

    async handleGreetingField (fields) {
        const hour = new Date().getHours();
        const day = new Date().getDay();
        const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        const dayName = days[day];
        let firstName = '';

        if (await this.isLoggedIn()) {
            firstName = (await this.getUser()).firstname;
        }

        let greetings = [];

        if (this.contextkey === 'web-antenne-de') {
            greetings = [
                {
                    days: [1, 2, 3, 4],
                    slots: [
                        {
                            hours: [0, 1, 2, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                            messages: [
                                `Servus, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [3, 4, 5, 6, 7, 8, 9],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                            ],
                        },
                    ],
                }, {
                    days: [5],
                    slots: [
                        {
                            hours: [3, 4, 5, 6, 7, 8, 9],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                            ],
                        }, {
                            hours: [0, 1, 2, 10, 11, 12, 13, 14],
                            messages: [
                                `Servus, ${firstName}!`,
                            ],
                        }, {
                            hours: [15, 16, 17, 18, 19, 20, 21, 22, 23],
                            messages: [
                                `Schönes Wochenende, ${firstName}!`,
                            ],
                        },
                    ],
                }, {
                    days: [6], // saturday
                    slots: [
                        {
                            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                            messages: [
                                `Schönes Wochenende, ${firstName}!`,
                            ],
                        }, {
                            hours: [15, 16, 17, 18, 19, 20, 21, 22, 23],
                            messages: [
                                `Servus, ${firstName}!`,
                            ],
                        },

                    ],
                }, {
                    days: [0], // sunday
                    slots: [
                        {
                            hours: [0, 1, 2, 16, 17, 18, 19, 20, 21, 22, 23],
                            messages: [
                                `Servus, ${firstName}!`,
                            ],
                        }, {
                            hours: [3, 4, 5, 6, 7, 8, 9, 10],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                            ],
                        }, {
                            hours: [11, 12, 13, 14, 15],
                            messages: [
                                `Schönen Sonntag, ${firstName}!`,
                            ],
                        },

                    ],
                },
            ];
        } else if (this.contextkey.includes('web-rockantenne')) {
            // Base RA greetings
            greetings = [
                {
                    days: [1, 2, 3, 4, 5],
                    slots: [
                        {
                            hours: [4, 5, 6, 7, 8, 9, 10],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                                `${firstName} - rock den Tag!`,
                                `Let's rock, ${firstName}!`,
                                `Have a nice day, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [11, 12],
                            messages: [
                                `Hi noon, ${firstName}!`,
                                `${firstName} - Let there be rock!`,
                            ],
                        }, {
                            hours: [13, 14, 15, 16, 17],
                            messages: [
                                `Hallo, ${firstName}!`,
                                `${firstName} - keep on rocking!`,
                                `Up the Irons, ${firstName}!`,
                                `Let's rock, ${firstName}!`,
                            ],
                        }, {
                            hours: [18, 19, 20, 21],
                            messages: [
                                `Hallo, ${firstName}!`,
                                `Rockigen Abend, ${firstName}!`,
                                `Up the Irons, ${firstName}!`,
                                `Let's rock, ${firstName}!`,
                            ],
                        }, {
                            hours: [0, 1, 2, 3, 22, 23],
                            messages: [
                                `Hallo, ${firstName}!`,
                                `Roch the Night, ${firstName}!`,
                                `Noch wach, ${firstName}?`,
                                `${firstName} - rock die Nacht mit uns!`,
                            ],
                        },
                    ],
                }, {
                    days: [6], // saturday
                    slots: [
                        {
                            hours: [4, 5, 6],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                                `${firstName} - rock den Tag!`,
                                `Let's rock, ${firstName}!`,
                                `Have a nice day, ${firstName}!`,
                            ],
                        }, {
                            hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                            messages: [
                                `Schönen Samstag, ${firstName}!`,
                                `${firstName} - rock den Samstag!`,
                                `Rockendes Wochenende, ${firstName}!`,
                                `Have a rockin' weekend, ${firstName}!`,
                            ],
                        }, {
                            hours: [0, 1, 2, 3, 22, 23],
                            messages: [
                                `Hallo, ${firstName}!`,
                                `Roch the Night, ${firstName}!`,
                                `Noch wach, ${firstName}?`,
                                `${firstName} - rock die Nacht mit uns!`,
                            ],
                        },
                    ],
                }, {
                    days: [0], // sunday
                    slots: [
                        {
                            hours: [4, 5, 6],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                                `${firstName} - rock den Tag!`,
                                `Let's rock, ${firstName}!`,
                                `Have a nice day, ${firstName}!`,
                            ],
                        }, {
                            hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                            messages: [
                                `Schönen Sonntag, ${firstName}!`,
                                `${firstName} - rock den Sonntag!`,
                                `Sunday, rocky Sunday, ${firstName}!`,
                                `Have a rocking Sunday, ${firstName}!`,
                            ],
                        }, {
                            hours: [0, 1, 2, 3, 22, 23],
                            messages: [
                                `Hallo, ${firstName}!`,
                                `Roch the Night, ${firstName}!`,
                                `Noch wach, ${firstName}?`,
                                `${firstName} - rock die Nacht mit uns!`,
                            ],
                        },
                    ],
                },
            ];
        } else if (this.contextkey.includes('web-oldie-antenne-de')) {
            greetings = [
                {
                    days: [1, 2, 3, 4, 5, 6, 0],
                    slots: [
                        {
                            hours: [0, 1, 2, 3],
                            messages: [
                                `Hallo, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [4, 5, 6, 7, 8],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [9, 10],
                            messages: [
                                `Schönen ${dayName}, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [11, 12, 13],
                            messages: [
                                `Hallo, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [18, 19, 20, 21, 22, 23],
                            messages: [
                                `Guten Abend, ${firstName}!`,
                            ],
                        },
                    ],
                }, {
                    days: [1, 2, 3, 4, 5],
                    slots: [
                        {
                            hours: [14, 15, 16, 17],
                            messages: [
                                `Schönen Nachmittag, ${firstName}!`,
                            ],
                        },
                    ],
                }, {
                    days: [0, 6],
                    slots: [
                        {
                            hours: [11, 12, 13, 14, 15, 16, 17],
                            messages: [
                                `Schönes Wochenende, ${firstName}!`,
                            ],
                        },
                    ],
                },
            ];
        } else {
            greetings = [
                {
                    days: [1, 2, 3, 4, 5, 6, 0],
                    slots: [
                        {
                            hours: [0, 1, 2, 18, 19, 20, 21, 22, 23],
                            messages: [
                                `Guten Abend ${firstName}!`,
                            ],
                        },
                        {
                            hours: [3, 4, 5, 6, 7, 8, 9],
                            messages: [
                                `Guten Morgen, ${firstName}!`,
                            ],
                        },
                        {
                            hours: [10, 11, 12, 13, 14, 15, 16, 17],
                            messages: [
                                `Guten Tag, ${firstName}!`,
                            ],
                        },
                    ],
                },
            ];
        }

        greetings.forEach(greeting => {
            if (greeting.days.includes(day)) {
                greeting.slots.forEach(slot => {
                    if (slot.hours.includes(hour)) {
                        greetings = slot.messages;
                    }
                });
            }
        });

        // Regional RA Greetings
        if (day !== 6 && day !== 0) {
            if (this.contextkey === 'web-rockantenne-hamburg') {
                greetings.push(`Moin ${firstName}!`);

                if (await this.isLoggedIn()) {
                    greetings.push(`Na ${firstName}?`);
                }
            }

            if (this.contextkey === 'web-rockantenne-at' || this.contextkey === 'web-rockantenne-bayern') {
                greetings.push(`Servus ${firstName}!`);
            }
        }

        if (hour >= 11 && hour < 13) {
            if (this.contextkey !== 'web-rockantenne-hamburg' && this.contextkey !== 'web-oldie-antenne-de') {
                greetings.push(`Mahlzeit, ${firstName}!`);
            }
        }

        let greeting = greetings[Math.floor(Math.random() * greetings.length)];

        if (await this.isLoggedIn() === false) {
            greeting = greeting.replace(/(,\s!)|(\s!)/, '!').replace(/(,\s\?)|(\s\?)/, '?').replace(/(^\s-\s)/, '');
            greeting = greeting.replace(/^./, greeting[0].toUpperCase());
        }

        fields.forEach(node => {
            node.innerText = greeting;
        });
    }

    async handleUserField (fields) {
        if (await this.isLoggedIn() !== true) {
            return;
        }
        const user = await this.getUser();
        fields.forEach(node => {
            const variableToFill = node.dataset.userfield;
            if (user[variableToFill]) {
                node.innerText = user[variableToFill];
            } else {
                this.logger.warn(
                    'Can not fill node with userfield, because its not set',
                    { variableToFill, node, user },
                );
            }
        });
    }

    async handleBookmarkWidget (elements) {
        const isLoggedIn = await this.isLoggedIn();
        elements.forEach((element) => {
            const category = element.dataset.bookmarkcategory;
            const layout = element.dataset.bookmarkwidget;

            if (isLoggedIn === false) {
                // Element has is-skeleton class which turns off pointer events. Override this.
                element.style.pointerEvents = 'inherit';

                element.addEventListener('click', e => {
                    this.openRegWall();
                });

                this.bookmarkService.renderNoBookmarksMessage(element, layout);
                this.bookmarkService.removeNoResultsLink(element);
            } else {
                this.bookmarkService.renderBookmarkWidget(element, category, layout);
            }
        });
    }

    /**
     * @param {NodeListOf<HTMLButtonElement>} buttons
     */
    async handleLikeButton (buttons) {
        const isLoggedIn = await this.isLoggedIn();

        for (const button of buttons) {
            this.checkLikeButton(button);

            /**
             * Ensure we’re not registering a click listener on the like button more than once.
             */
            if (button.classList.contains('is-initialized')) {
                return;
            }

            button.classList.add('is-initialized');

            button.addEventListener('click', e => {
                e.preventDefault();

                if (!isLoggedIn) {
                    this.openRegWall();
                    return false;
                }

                /** @type {DataLikeAttribute} */
                const payload = JSON.parse(button.dataset.like || '{}');

                if (!this.hasValidDataLikeAttributes(payload)) {
                    this.logger.warn('Like button is missing "type" or "identifier" attribute', {
                        button,
                        payload,
                    });

                    return false;
                }

                if (this.checkLike(payload)) {
                    this.removeLike(payload);
                    button.innerHTML = button.innerHTML.replaceAll('_filled', '_outline');
                } else {
                    this.addLike(payload);
                    button.innerHTML = button.innerHTML.replaceAll('_outline', '_filled');
                }

                return false;
            });
        }
    }

    /**
     * Adjust the given like `button` to its correct state (filled/outlined).
     *
     * @param {HTMLButtonElement} button
     */
    checkLikeButton (button) {
        /** @type {DataLikeAttribute} */
        const payload = JSON.parse(button.dataset.like || '{}');

        if (!this.hasValidDataLikeAttributes(payload)) {
            this.logger.warn('Like button is missing "type" or "identifier" attribute', {
                button,
                payload,
            });
        }

        if (this.checkLike(payload)) {
            button.innerHTML = button.innerHTML.replaceAll('_outline', '_filled');
        } else {
            button.innerHTML = button.innerHTML.replaceAll('_filled', '_outline');
        }
    }

    /**
     * Determine whether the given like `data` attributes are valid.
     *
     * @param {DataLikeAttribute} payload
     *
     * @returns {Boolean}
     */
    hasValidDataLikeAttributes (payload = {}) {
        return !!payload.type && !!payload.identifier;
    }

    /**
     * @param {NodeListOf<HTMLButtonElement>} buttons
     */
    async handleBookmarkButton (buttons) {
        const isLoggedIn = await this.isLoggedIn();

        buttons.forEach(async button => {
            /** @type {BookmarkDataAttribute} */
            const bookmarkData = JSON.parse(button.dataset.bookmark || 'null');

            if (isLoggedIn) {
                await this.checkBookmarkButton(button, bookmarkData);
            }

            /**
             * Ensure we’re not registering a click listener on the bookmark button more
             * than once. This may happen when generating the "playlist" on a channel
             * detail page where we dynamically add DOM nodes and connect them here.
             */
            if (button.classList.contains('is-initialized')) {
                return;
            }

            button.classList.add('is-initialized');

            button.addEventListener('click', async e => {
                e.preventDefault();

                if (!isLoggedIn && !window.nativeJsBridge.isWebview) {
                    // exit early if non-webview and user is not logged-in
                    this.openRegWall();
                    return false;
                }

                // we need to freshly retrieve the 'data-bookmark' values to process the current values
                /** @type {BookmarkDataAttribute} */
                const bookmarkData = JSON.parse(button.dataset.bookmark || 'null');

                if (!this.bookmarkService.isValidBookmarkData(bookmarkData)) {
                    this.logger.warn('Bookmark button is missing "category" or "content_id" attribute', {
                        button,
                        bookmarkData,
                    });

                    return false;
                }

                button.classList.add('u-pulsate');

                try {
                    if (await this.bookmarkService.isBookmarked(bookmarkData)) {
                        try {
                            await this.bookmarkService.removeBookmark(bookmarkData);
                            button.innerHTML = button.innerHTML.replaceAll('_filled', '_outline');

                            const bookmarkContainer = button.closest('[data-slideidx], [data-bookmark-audioitem]');
                            if (bookmarkContainer) {
                                bookmarkContainer.classList.add('has-bookmark-removed');
                            }
                        } catch (error) {
                            button.innerHTML = button.innerHTML.replaceAll('_outline', '_filled');
                            this.logger.error('Failed to remove bookmark', { error, bookmarkData });
                        }
                    } else {
                        try {
                            await this.bookmarkService.addBookmark(bookmarkData);
                            button.innerHTML = button.innerHTML.replaceAll('_outline', '_filled');
                        } catch (error) {
                            button.innerHTML = button.innerHTML.replaceAll('_filled', '_outline');
                            this.logger.error(`Failed to add bookmark: ${error.message}`, { error, bookmarkData });
                        }
                    }
                } catch (error) {
                    this.logger.error('Error when handling bookmark', error);
                    if (
                        error instanceof ApiError &&
                        error.statusCode === 401 &&
                        error.code === 'authentication-error'
                    ) {
                        this.openRegWall();
                    }
                }

                button.classList.remove('u-pulsate');
                return false;
            });
        });
    }

    /**
     * Adjust the given bookmark `button` to its correct state (filled/outlined).
     *
     * @param {HTMLButtonElement} button
     */
    async checkBookmarkButton (button, bookmarkData = null) {
        if (!bookmarkData) {
            /** @type {BookmarkDataAttribute} */
            bookmarkData = JSON.parse(button.dataset.bookmark || 'null');

            if (!this.bookmarkService.isValidBookmarkData(bookmarkData)) {
                this.logger.warn('Bookmark button is missing "category" or "content_id" attribute. Ignoring button', {
                    button,
                    bookmarkData,
                });
                return;
            }
        }

        try {
            if (await this.bookmarkService.isBookmarked(bookmarkData)) {
                button.innerHTML = button.innerHTML.replaceAll('_outline', '_filled');
            } else {
                button.innerHTML = button.innerHTML.replaceAll('_filled', '_outline');
            }
        } catch (error) {
            // invalid bookmark data
        }
    }

    async isLoggedIn () {
        try {
            await this.getUser();
            return true;
        } catch (error) {
            return false;
        }
    }

    async getUser () {
        if (this.user && this.user.id) {
            return this.user;
        }
        let cookie = this.commonMethods.getCookie(this.publicCookieName);
        if (cookie === '' || !cookie || cookie.indexOf('.') === -1) {
            // try to load cookie with legacy name
            cookie = this.commonMethods.getCookie(this.contextkey + '-user');
        }
        if (cookie === '' || !cookie || cookie.indexOf('.') === -1) {
            if (window.nativeJsBridge.isWebview) {
                try {
                    await this.apiClient.getAbyAuthTokenFromNative();
                    await this.apiClient.post('/usersession');
                    cookie = this.commonMethods.getCookie(this.publicCookieName);
                    if (cookie === '' || !cookie || cookie.indexOf('.') === -1) {
                        throw new Error('No valid session found');
                    }
                } catch (error) {
                    throw new Error('No valid session found');
                }
            } else {
                throw new Error('No valid session found');
            }
        }
        try {
            const [jwtPayload] = cookie.split('.');
            const jsonstring = this.commonMethods.b64DecodeUnicode(jwtPayload);
            const user = JSON.parse(jsonstring);

            if (!user.id) {
                throw new Error('Session has no id');
            }

            if (!user.firstname) {
                throw new Error('Session has no firstname');
            }

            this.logger.log('User found', { user });
            this.user = user;
            return this.user;
        } catch (error) {
            this.logger.error(error);
            throw new Error('Could not parse session information');
        }
    }

    /**
     * @param {DataLikeAttribute} payload
     *
     * @returns {String}
     */
    getLikeIdentifier (payload) {
        return `${payload.type}.${payload.identifier}`;
    }

    /**
     * @param {DataLikeAttribute} payload
     */
    addLike (payload) {
        const identifier = this.getLikeIdentifier(payload);
        this.logger.log('Adding like', { identifier, payload });
        // get likes, add current, save likes
        const likes = this.getAllLikes();
        likes.push(identifier);
        this.saveLikes(likes);
        // track like
        this.trackingService.track('fix:like', payload);
    }

    /**
     * @param {DataLikeAttribute} payload
     */
    removeLike (payload) {
        const identifier = this.getLikeIdentifier(payload);
        this.logger.log('Removing like', { identifier, payload });
        // get all likes, find index of current like
        const allLikes = this.getAllLikes();
        const index = allLikes.indexOf(identifier);
        if (index > -1) {
            // if found, remove item, save likes
            allLikes.splice(index, 1);
            this.saveLikes(allLikes);
        }
    }

    /**
     * @param {string[]} likes
     */
    saveLikes (likes) {
        if (Array.isArray(likes) === false) {
            throw new TypeError('likes must be an array');
        }
        // make likes unique (remove duplicates)
        likes = [...new Set(likes)];

        // limit array length to prevent too many data in localstorage
        likes.length = Math.min(likes.length, 10000);

        // save
        window.localStorage.setItem(this.likeStorageKey, JSON.stringify(likes));
    }

    /**
     * @returns {string[]}
     */
    getAllLikes () {
        const storedLikes = JSON.parse(window.localStorage.getItem(this.likeStorageKey));
        if (Array.isArray(storedLikes) === false) {
            return [];
        }
        return storedLikes;
    }

    /**
     * @param {DataLikeAttribute} payload
     *
     * @returns {Boolean}
     */
    checkLike (payload) {
        return this.getAllLikes().includes(this.getLikeIdentifier(payload));
    }

    clearUserStorage () {
        localStorage.removeItem(this.likeStorageKey);
        this.bookmarkService.clearBookmarksDataFromLocalStorage();
    }
}

export default User;
