'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';
import { ApiError } from './ApiError';

class Form {
    getClassName () {
        return 'Form';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disbale logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;
        /** @private */
        this.apiClient = new ApiClient();

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const forms = document.querySelectorAll('.c-form');
        forms.forEach(form => this.initForm(form));
    }

    /**
     * @param {HTMLElement} form
     */
    initForm (form) {
        // Mark inputs as 'dirty' once they are changed (used for error rednering)
        form.querySelectorAll('input, textarea, select').forEach(input => {
            input.addEventListener('input', (e) => {
                e.target.classList.add('is-dirty');
            });
        });

        const locationSelectContainer = form.querySelector('[data-formlocationselect]');
        if (locationSelectContainer) {
            this.initLocationSelectContainer(locationSelectContainer);
        }

        // Prevent multiple submits of the same form
        form.addEventListener('submit', (e) => {
            if (e.target.classList.contains('is-submitted')) {
                this.logger.warn('Preventing from multiple submits');
                e.preventDefault();
                return false;
            }

            e.target.classList.add('is-submitted');

            e.target.querySelectorAll('button[type="submit"]').forEach(button => {
                button.textContent = button.dataset.loadingText || 'Bitte warten…';
                button.classList.add('is-loading');
            });
        });

        this.initStickybottomConsent(form);
        this.handlePasswordToggle(form);
        this.checkGmailDomain(form);
    }

    checkGmailDomain (form) {
        form.querySelectorAll('input[type="email"]').forEach(input => {
            input.addEventListener('input', (e) => {
                const element = e.target;
                const value = element.value.trim();
                if (value.endsWith('@gmail.de')) {
                    element.setCustomValidity('Meinst du @gmail.com? E-Mail-Adressen mit @gmail.de gibt es nicht!');
                    element.reportValidity();
                } else {
                    element.setCustomValidity('');
                }
            });
        });
    }

    handlePasswordToggle (form) {
        form.querySelectorAll('button[data-passwordtoggle]').forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const input = e.target.closest('.c-form__field').querySelector('input');
                input.type = (input.type === 'password') ? 'text' : 'password';
                return false;
            });
        });
    }

    initLocationSelectContainer (container) {
        const zipInput = container.querySelector('input[name="zipcode"]');
        const cityInput = container.querySelector('input[name="city"]');
        const countrySelect = container.querySelector('select[name="country"]');
        const locationIdInput = container.querySelector('input[name="location_id"]');

        zipInput.addEventListener('input', (e) => {
            this.callGeocodeApi(e, zipInput, cityInput, countrySelect, locationIdInput);
        });
        countrySelect.addEventListener('change', (e) => {
            this.callGeocodeApi(e, zipInput, cityInput, countrySelect, locationIdInput);
        });
        if (zipInput.value !== '') {
            this.callGeocodeApi(null, zipInput, cityInput, countrySelect, locationIdInput);
        }
    }

    callGeocodeApi (e, zipInput, cityInput, countrySelect, locationIdInput) {
        if (this.lastLocationGeocodePromise) {
            this.logger.log('aborting previous geocode request', this.lastLocationGeocodePromise);
            this.lastLocationGeocodePromise.abort();
        }

        if (String(zipInput.value).length < 4) {
            return;
        }

        this.lastLocationGeocodePromise = this.apiClient.get(
            `/geocode/${countrySelect.value}/${zipInput.value}`,
        );
        this.lastLocationGeocodePromise.then((locations) => {
            // this.logger.log('got locations', locations);
            if (locations && locations[0] && locations[0].zipcode === zipInput.value) {
                this.showAndUpdateCitySelect(cityInput, locations, locationIdInput);
            }
        }).catch((error) => {
            this.logger.log('Geocoding error', error);
            cityInput.querySelectorAll('option').forEach(option => option.remove());
            if (error instanceof ApiError && (error.statusCode === 404 || error.statusCode >= 500)) {
                this.showAltCityField(cityInput, locationIdInput);
            } else {
                cityInput.placeholder = 'Bitte gib eine gültige PLZ ein';
            }
        }).finally(() => {
            this.lastLocationGeocodePromise = null;
        });
    }

    showAndUpdateCitySelect (cityInput, locations = [], locationIdInput) {
        const parentElement = cityInput.parentElement;
        cityInput.value = '';
        cityInput.setAttribute('disabled', 'disabled');
        parentElement.style.display = 'none';

        locationIdInput.value = '';

        let citySelect = null;
        if (!parentElement.parentElement.querySelector('.alt-city-select')) {
            parentElement.parentElement.insertAdjacentHTML(
                'beforeend',
                window.antenne.templates.citySelect,
            );
            // Update locationIdInput when a city is selected
            citySelect = parentElement.parentElement.querySelector('.alt-city-select select');
            citySelect.dataset.locations = '[]';
            citySelect.addEventListener(
                'change',
                e => {
                    JSON.parse(citySelect.dataset.locations).forEach((loc) => {
                        if (loc.name === e.target.value) {
                            locationIdInput.value = loc.id;
                        }
                    });
                },
            );
        }
        citySelect = parentElement.parentElement.querySelector('.alt-city-select select');
        citySelect.dataset.locations = JSON.stringify(locations);
        citySelect.querySelectorAll('option').forEach(option => option.remove());

        // citySelect.removeAttribute('disabled');
        // citySelect.style.display = 'block';
        // citySelect.parentElement.querySelector('.c-form__dropdown-icon').style.display = 'block';

        const previousValue = document.querySelector('input[name="city"]').value;
        let selectDefault = 'selected="selected"';
        locations.forEach((loc) => {
            let selected = '';
            if (loc.name === previousValue) {
                selectDefault = '';
                selected = 'selected="selected"';
                locationIdInput.value = loc.id;
            }
            citySelect.append(this.commonMethods.markupToElement(`
                <option value="${loc.name}" data-id="${loc.id}" ${selected}>${loc.name}</option>
            `));
        });

        citySelect.prepend(this.commonMethods.markupToElement(`
            <option value="" disabled="disabled" ${selectDefault}>Bitte Ort auswählen</option>
        `));
    }

    showAltCityField (cityInput, locationIdInput) {
        const parentElement = cityInput.parentElement;
        const citySelect = parentElement.parentElement.querySelector('.alt-city-select');
        if (citySelect) {
            citySelect.remove();
        }
        locationIdInput.value = '';

        cityInput.removeAttribute('disabled');
        parentElement.style.display = 'block';
    }

    initStickybottomConsent (form) {
        let buttonsAreSticky = false;
        let buttonsHaveBeenStickyBefore = false;
        const item = form.querySelector('.o-stickybottom');

        if (item && form.querySelector('.o-stickybottom-observer')) {
            item.classList.add('has-jsstickyhandler');

            const observer = new IntersectionObserver((entries, observer) => {
                // no intersection with screen
                if (entries[0].intersectionRatio === 0) {
                    if (buttonsHaveBeenStickyBefore !== true) {
                        item.classList.add('is-disabled');
                        window.document.documentElement.classList.add('has-stickybottom');
                        buttonsAreSticky = true;
                    }
                    buttonsHaveBeenStickyBefore = true;
                    // fully intersects with screen
                } else if (entries[0].intersectionRatio === 1) {
                    item.classList.remove('is-disabled');
                    window.document.documentElement.classList.remove('has-stickybottom');
                    buttonsAreSticky = false;
                }
            }, {
                threshold: [0, 1],
            });

            observer.observe(form.querySelector('.o-stickybottom-observer'));

            const alerttext = 'Bitte lese den gesamten Text, dazu scrolle bitte weiter nach unten. ' +
                              'Die Buttons aktivieren sich dann automatisch.';

            const buttons = form.querySelectorAll('.o-stickybottom .c-button');
            buttons.forEach(button => {
                button.addEventListener('click', (e) => {
                    if (buttonsAreSticky === true) {
                        e.preventDefault();
                        alert(alerttext);
                        return false;
                    }
                });
            });
        }
    }
}

export default Form;
