/**
 * GeolocationWidget Class
 *
 * @version 1.0.0
 * @copyright 2032 SEDA.digital GmbH & Co. KG
 */

'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./AntenneConfig').default} AntenneConfig
 * @typedef {import('./CommonMethods').default} CommonMethods
 */
import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';
import GeolocationPermissionError from './GeolocationPermissionError';

class GeolocationWidget {
    getClassName () { return 'GeolocationWidget'; }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler, dialogService) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;
        this.dialogService = dialogService;

        /** @private */
        this.apiClient = new ApiClient();

        this.weatherStorageKey = 'widgets.weather';
        this.weatherCacheExpiry = 900; // seconds

        this.trafficStorageKey = 'widgets.traffic';
        this.trafficCacheExpiry = 900; // seconds

        this.speedcamerasStorageKey = 'widgets.speedcameras';
        this.speedcamerasCacheExpiry = 900; // seconds

        this.handlers = {
            'mini-weather': (node) => this.handleMiniWeatherWidget(node),
            weather: (node) => this.handleWeatherWidget(node),
            traffic: (node) => this.handleTrafficWidget(node),
            speedcameras: (node) => this.handleSpeedcamerasWidget(node),
            'location-settings': (node) => this.handleLocationSettings(node),
            'weather-search': (node) => this.handleWeatherSearch(node),
        };

        this.navigationHandler
            .on('render', () => {
                this.init();
            })
            .on('ready', () => {
                this.templateVariation = this.commonMethods.getTemplateVariation();
                this.init();
            });
    }

    init () {
        this.logger.log('Init!');
        const widgets = document.querySelectorAll('[data-geolocationwidget]');

        widgets.forEach(widget => {
            this.onElementVisibilityChange(widget, (isVisible) => {
                if (isVisible !== true) {
                    return;
                }
                const widgetType = widget.dataset.geolocationwidget;
                this.logger.log('Loading widget', { widgetType, widget });
                if (!this.handlers[widgetType]) {
                    this.logger.error('Handler for widget ' + widgetType + ' not configured');
                    return;
                }
                this.handlers[widgetType](widget);
            });
        });
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleLocationSettings (element) {
        const errorTemplate = element.querySelector('[data-geolocationErrorTemplate]');
        if (!errorTemplate) {
            this.logger.error('Error template element not found');
            return;
        }
        const textWrapper = element.querySelector('[data-waitingPlaceholder]');
        if (!textWrapper) {
            this.logger.error('Text wrapper element not found');
            return;
        }

        textWrapper.innerText = 'Bitte warten…';

        let permissionState = '';
        try {
            permissionState = await this.commonMethods.getGeolocationPermission();
        } catch (error) {
            if (error instanceof GeolocationPermissionError && error.state === 'not-supported') {
                textWrapper.innerText = 'Dein Browser erlaubt es uns nicht, deinen Standort zu ermitteln.';
                return;
            } else {
                throw error;
            }
        }

        this.logger.log('Geolocation permission state: ' + permissionState);

        const successMessage = 'Standortfreigabe ist erfolgreich aktiviert.';
        if (permissionState === 'granted') {
            textWrapper.innerText = successMessage;
            return;
        }

        if (permissionState === 'prompt') {
            this.logger.log('Permission-request for Geolocation not yet prompted');

            try {
                /**
                 * iOS does not change permissionState to "granted".
                 * Check if we have geolocation data already in cache.
                 */
                await this.commonMethods.getGeolocation({ askForPermission: false });
                textWrapper.innerText = successMessage;

                return;
            } catch (error) {
                //  Don't have geolocation permission yet. Ask user to give us permission.
                // Script just continues on.
            }

            const requestTemplate = element.querySelector('[data-geolocationRequestTemplate]');
            if (requestTemplate) {
                requestTemplate.classList.remove('u-hide');
                textWrapper.classList.add('u-hide');
                const button = requestTemplate.querySelector('[data-geolocationPromptButton]');
                button.addEventListener(
                    'click',
                    (e) => {
                        this.logger.log('Requesting permission for geolocation');
                        button.innerText = 'Warte auf Freigabe & Standort…';
                        button.classList.add('is-loading');
                        e.preventDefault();
                        this.commonMethods.getGeolocation({ askForPermission: true }).then(() => {
                            textWrapper.innerText = successMessage;
                            const hasOkButton = element.querySelector(
                                '[data-geolocation-modal-close]',
                            );
                            if (this.GelocationDialogObject && hasOkButton === null) {
                                const OkButton = document.createElement('button');
                                OkButton.innerText = 'OK';
                                OkButton.classList.add(
                                    'c-button',
                                    'u-extra-small-margin--bottom',
                                    'u-extra-small-margin--top',
                                );
                                OkButton.setAttribute('data-geolocation-modal-close', '');
                                OkButton.addEventListener('click', async (event) => {
                                    event.preventDefault();

                                    await this.redirectToWeatherDetailPage();
                                });
                                textWrapper.append(OkButton);
                            }
                        }).catch((error) => {
                            this.logger.error('Error when requesting geolocation', error);
                            textWrapper.innerText = 'Es trat ein Fehler auf.';
                            errorTemplate.classList.remove('u-hide');
                        }).finally(() => {
                            textWrapper.classList.remove('u-hide');
                            requestTemplate.classList.add('u-hide');
                        });
                    },
                    {
                        once: true,
                    },
                );
            }
            return;
        }

        // error
        if (permissionState !== 'denied') {
            throw new TypeError('Unexpected geolocation permission state: ' + permissionState);
        }

        textWrapper.classList.add('u-hide');
        errorTemplate.classList.remove('u-hide');
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleMiniWeatherWidget (element) {
        const loadingelement = this.commonMethods.markupToElement(`
            <div class="c-miniweather" data-mini-weather-loading>
                <span class="c-miniweather__icon u-widget-content-loading"></span>
            </div>
        `);

        const timeout = setTimeout(() => {
            element.append(loadingelement);
        }, 1000);

        try {
            const coordinates = await this.commonMethods.getGeolocation({ askForPermission: false });
            const weather = await this.getWeather(coordinates);

            clearTimeout(timeout);
            loadingelement.remove();

            this.renderMiniWeatherSuccess(element, weather);
        } catch (error) {
            clearTimeout(timeout);
            loadingelement.remove();

            if (error instanceof window.GeolocationPositionError) {
                this.logger.error('Geolocation position error', error);
                this.renderMiniWeatherError(element, 'error');
                return;
            } else if (error instanceof GeolocationPermissionError) {
                if (error.state === 'prompt') {
                    this.logger.log('Permission-request for Geolocation not yet prompted');
                    // geolocation was not yet requested, user should be prompted
                    this.renderMiniWeatherError(element, 'noweather');
                    return;
                }
                this.logger.warn('Permission for Geolocation denied', {
                    message: error.message,
                    state: error.state,
                });
                this.renderMiniWeatherError(element, 'error');
                return;
            }

            this.logger.error(`${error.name}: ${error.message}`, { error });
            this.renderMiniWeatherError(element, 'noweather');
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    renderMiniWeatherError (element, icon) {
        element.append(
            this.commonMethods.markupToElement(`
                <a class="c-miniweather" href="/einstellungen/standort">
                    ${this.commonMethods.getWeatherSvgIcon(icon, 'c-miniweather__icon')}
                </a>
            `),
        );
    }

    /**
     *
     * @param {HTMLElement} element
     */
    renderMiniWeatherSuccess (element, data) {
        // eslint-disable-next-line camelcase
        const { weather_type_daytime, weather_type_id } = data.weather.current;
        // eslint-disable-next-line camelcase
        const iconId = `${weather_type_daytime}-${weather_type_id}`;

        let markup = `
            <span class="u-sr-only">Das aktuelle Wetter für ${data.location.city}:</span>
            <span class="c-miniweather__temperature">${parseInt(data.weather.current.temperature)}°</span>
            ${this.commonMethods.getWeatherSvgIcon(iconId, 'c-miniweather__icon')}
        `;

        if (data.link) {
            markup = `
                <a class="c-miniweather" href="${data.link}" title="zur Wettervorhersage für ${data.location.city}">
                    ${markup}
                </a>
            `;
        } else {
            markup = `
                <div class="c-miniweather" title="Wettervorhersage für ${data.location.city}">
                    ${markup}
                </div>
            `;
        }

        element.append(this.commonMethods.markupToElement(markup));
    }

    /**
     *
     * @param {GeolocationPosition} coordinates
     */
    async getWeather (coordinates) {
        const latitude = coordinates.coords.latitude;
        const longitude = coordinates.coords.longitude;

        // Rounding coordinates for caching, to make sure (only) if location signifcantly changes we fetch fresh data
        // see https://wiki.openstreetmap.org/wiki/Precision_of_coordinates
        const locationKey = `${latitude.toFixed(4)},${longitude.toFixed(4)}`;

        let { expireson, locationKey: storedLocationKey, data: weather } = JSON.parse(
            localStorage.getItem(this.weatherStorageKey) || '{}',
        );

        if (!expireson || expireson < Date.now() || !storedLocationKey || storedLocationKey !== locationKey) {
            const searchParams = new URLSearchParams({
                coordinates: latitude + ',' + longitude,
                elevation: parseInt(coordinates.coords.altitude || 263),
            });
            weather = await this.apiClient.get('/weather/summary?' + searchParams.toString());

            localStorage.setItem(this.weatherStorageKey, JSON.stringify({
                expireson: Date.now() + this.weatherCacheExpiry * 1000,
                locationKey,
                data: weather,
            }));
        }

        return weather;
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleWeatherWidget (element) {
        try {
            const coordinates = await this.commonMethods.getGeolocation({ askForPermission: false });
            const weather = await this.getWeather(coordinates);
            this.renderWeatherSuccess(element, weather);
        } catch (error) {
            if (error instanceof window.GeolocationPositionError) {
                this.logger.error('Geolocation position error', error);
                // continue with error handling...
            } else if (error instanceof GeolocationPermissionError) {
                if (error.state === 'prompt') {
                    this.logger.log('Permission-request for Geolocation not yet promtped');
                    // geolocation was not yet requested, user should be prompted
                    this.renderWeatherError(element, 'noweather');
                    return;
                }
                this.logger.warn('Permission for Geolocation denied', {
                    message: error.message,
                    state: error.state,
                });
            }

            this.logger.error(`${error.name}: ${error.message}`, { error });
            this.renderWeatherError(element, 'error');
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    renderWeatherError (element) {
        element.classList.remove('is-skeleton', 'is-loading');
        this.logger.log('rendering weather error');
        const city = element.querySelector('[data-weather-city]');
        const link = element.querySelector('[data-weather-link]');

        if (city) {
            city.parentNode.classList.add('u-hide');
        }

        if (link) {
            link.href = '/service/wetter/';
        }
    }

    /**
     *
     * @param {HTMLElement} element
     * @param {Array} data
     */
    renderWeatherSuccess (element, data) {
        element.classList.remove('is-skeleton', 'is-loading');
        this.removeDefaultContent(element);
        const city = element.querySelector('[data-weather-city]');
        const link = element.querySelector('[data-weather-link]');
        const temperature = element.querySelector('[data-weather-temperature]');
        const type = element.querySelector('[data-weather-type]');
        const iconElement = element.querySelector('[data-weather-icon]');
        if (city) {
            city.parentNode.classList.remove('u-hide');
            city.textContent = data.location.city;
        }

        if (link && data.link) {
            link.href = data.link;
        }

        if (temperature) {
            temperature.textContent = parseInt(data.weather.current.temperature) + '°';
        }

        if (type) {
            type.textContent = data.weather.current.weather_type_name;
        }

        if (iconElement) {
            const icon = data.weather.current.weather_type_daytime + '-' + data.weather.current.weather_type_id;

            iconElement.replaceWith(this.commonMethods.markupToElement(
                this.commonMethods.getWeatherSvgIcon(icon),
            ));
        }
    }

    /**
     *
     * @param {GeolocationPosition} coordinates
     */
    async getTraffic (coordinates) {
        const latitude = coordinates.coords.latitude;
        const longitude = coordinates.coords.longitude;

        // Rounding coordinates for caching, to make sure (only) if location signifcantly changes we fetch fresh data
        // see https://wiki.openstreetmap.org/wiki/Precision_of_coordinates
        const locationKey = `${latitude.toFixed(4)},${longitude.toFixed(4)}`;

        let { expireson, locationKey: storedLocationKey, data: traffic } = JSON.parse(
            localStorage.getItem(this.trafficStorageKey) || '{}',
        );

        if (!expireson || expireson < Date.now() || !storedLocationKey || storedLocationKey !== locationKey) {
            const searchParams = new URLSearchParams({
                coordinates: latitude + ',' + longitude,
            });
            traffic = await this.apiClient.get('/traffic/reports/summary?' + searchParams.toString());

            localStorage.setItem(this.trafficStorageKey, JSON.stringify({
                expireson: Date.now() + this.trafficCacheExpiry * 1000,
                locationKey,
                data: traffic,
            }));
        }

        return traffic;
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleTrafficWidget (element) {
        try {
            const coordinates = await this.commonMethods.getGeolocation({ askForPermission: false });
            const traffic = await this.getTraffic(coordinates);
            this.renderTrafficSuccess(element, traffic);
        } catch (error) {
            if (error instanceof window.GeolocationPositionError) {
                this.logger.error('Geolocation position error', error);
                // continue with error handling...
            } else if (error instanceof GeolocationPermissionError) {
                if (error.state === 'prompt') {
                    this.logger.log('Permission-request for Geolocation not yet promtped');
                    // geolocation was not yet requested, user should be prompted
                    this.renderTrafficError(element, 'notraffic');
                    return;
                }
                this.logger.warn('Permission for Geolocation denied', {
                    message: error.message,
                    state: error.state,
                });
            }

            this.logger.error(`${error.name}: ${error.message}`, { error });
            this.renderTrafficError(element, 'error');
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    renderTrafficError (element) {
        element.classList.remove('is-skeleton', 'is-loading');
        const icon = element.querySelector('[data-traffic-icon]');
        const link = element.querySelector('[data-traffic-link]');

        if (icon) {
            icon.classList.add('u-hide');
        }
        if (link) {
            link.href = '/service/aktuelle-verkehrsmeldungen/';
        }
    }

    /**
     *
     * @param {HTMLElement} element
     * @param {Array} data
     */
    renderTrafficSuccess (element, data) {
        element.classList.remove('is-skeleton', 'is-loading');
        this.removeDefaultContent(element);
        const reportsWrapper = element.querySelector('[data-traffic-reports]');
        const link = element.querySelector('[data-traffic-link]');
        if (link) {
            link.href = data.link;
        }
        if (reportsWrapper) {
            data.reports.slice(0, 6).forEach((report) => {
                reportsWrapper.append(this.commonMethods.markupToElement(`
                    <li>
                        <div class="c-badge c-badge--ingrid">
                            <span class="o-trafficsymbol">${report.street}</span>
                            <span class="c-badge__number">${report.count}</span>
                        </div>
                    </li>
                `));
            });
        }
    }

    /**
     *
     * @param {GeolocationPosition} coordinates
     */
    async getSpeedcameras (coordinates) {
        const latitude = coordinates.coords.latitude;
        const longitude = coordinates.coords.longitude;

        // Rounding coordinates for caching, to make sure (only) if location signifcantly changes we fetch fresh data
        // see https://wiki.openstreetmap.org/wiki/Precision_of_coordinates
        const locationKey = `${latitude.toFixed(4)},${longitude.toFixed(4)}`;

        let { expireson, locationKey: storedLocationKey, data: speedcameras } = JSON.parse(
            localStorage.getItem(this.speedcamerasStorageKey) || '{}',
        );

        if (!expireson || expireson < Date.now() || !storedLocationKey || storedLocationKey !== locationKey) {
            const searchParams = new URLSearchParams({
                coordinates: latitude + ',' + longitude,
            });
            speedcameras = await this.apiClient.get('/traffic/speedcameras/summary?' + searchParams.toString());

            localStorage.setItem(this.speedcamerasStorageKey, JSON.stringify({
                expireson: Date.now() + this.speedcamerasCacheExpiry * 1000,
                locationKey,
                data: speedcameras,
            }));
        }

        return speedcameras;
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleSpeedcamerasWidget (element) {
        try {
            const coordinates = await this.commonMethods.getGeolocation({ askForPermission: false });
            const traffic = await this.getSpeedcameras(coordinates);
            this.renderSpeedcameraSuccess(element, traffic);
        } catch (error) {
            if (error instanceof window.GeolocationPositionError) {
                this.logger.error('Geolocation position error', error);
                // continue with error handling...
            } else if (error instanceof GeolocationPermissionError) {
                if (error.state === 'prompt') {
                    this.logger.log('Permission-request for Geolocation not yet prompted');
                    // geolocation was not yet requested, user should be prompted
                    this.renderSpeedcameraError(element, 'notraffic');
                    return;
                }
                this.logger.warn('Permission for Geolocation denied', {
                    message: error.message,
                    state: error.state,
                });
            }

            this.logger.error(`${error.name}: ${error.message}`, { error });
            this.renderSpeedcameraError(element, 'error');
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    renderSpeedcameraError (element) {
        element.classList.remove('is-skeleton', 'is-loading');
        const icon = element.querySelector('[data-speedcamera-icon]');
        const link = element.querySelector('[data-speedcamera-link]');

        if (icon) {
            icon.classList.add('u-hide');
        }
        if (link) {
            link.href = '/service/aktuelle-blitzer/';
        }
    }

    /**
     *
     * @param {HTMLElement} element
     * @param {Array} data
     */
    renderSpeedcameraSuccess (element, data) {
        element.classList.remove('is-skeleton', 'is-loading');
        this.removeDefaultContent(element);
        const content = element.querySelector('[data-speedcamera-content]');
        const link = element.querySelector('[data-speedcamera-link]');
        if (link) {
            link.href = data.link;
        }
        if (content) {
            content.classList.remove('u-hide');

            // might only display parts of 2nd or 3rd report
            data.reports.slice(0, 3).forEach((report) => {
                report.street = report.street.replace('Straße', 'Str.').replace('straße', 'str.');
                content.append(this.commonMethods.markupToElement(`
                    <p class="u-no-margin u-line-clamp" style="--maxlines-small: 2;--maxlines-medium: 2">
                        <b>${report.street}</b> ${report.city}
                    </p>
                `));
            });

            this.isElementInViewport(element.querySelector('[data-speedcamera-content]'));
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    isElementInViewport (element) {
        this.logger.warn('visibility function: ', element);
        const parentrect = element.getBoundingClientRect();
        this.logger.warn('parentrect: ', parentrect);

        Array.from(element.children).forEach(child => {
            const childrect = child.getBoundingClientRect();
            this.logger.warn('childrect: ', childrect);

            if (childrect.bottom > parentrect.bottom) {
                this.logger.warn('Element is NOT in the viewport!');
                child.classList.add('u-hide');
            } else {
                this.logger.warn('Element is in the viewport!');
                child.classList.remove('u-hide');
            }
        });
    }

    /**
     *
     * @param {HTMLElement} element
     */
    onElementVisibilityChange (element, handler) {
        const options = {
            root: null, // = viewport
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    // It's visible
                    handler(true);
                    observer.unobserve(element);
                }
            });
        }, options);

        observer.observe(element);
    }

    /**
     * Removes all items marked with the class is-default-content
     * @param {HTMLElement} element
     */
    removeDefaultContent (element) {
        const items = element.querySelectorAll('.is-default-content');
        if (items.length > 0) {
            items.forEach(item => {
                item.remove();
            });
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    async handleWeatherSearch (element) {
        const btn = element.querySelector('button');

        btn.addEventListener('click', async (e) => {
            e.preventDefault();
            btn.classList.add('is-loading');
            try {
                await this.redirectToWeatherDetailPage();
            } catch (error) {
                if (error instanceof window.GeolocationPositionError) {
                    this.logger.error('Geolocation position error', error);
                    alert('Laden von Wetterdaten fehlgeschlagen.');
                // continue with error handling...
                } else if (error instanceof GeolocationPermissionError) {
                    if (error.state === 'prompt') {
                        this.logger.log('Permission-request for Geolocation not yet promtped');
                        // geolocation was not yet requested, user should be prompted
                        this.openGelocationDialog(btn);
                        this.logger.log('fail', error);
                        return;
                    }
                    this.logger.warn('Permission for Geolocation denied', {
                        message: error.message,
                        state: error.state,
                    });
                }

                // In the case that the user has disabled geolocation services. Disable our button
                this.logger.error(`${error.name}: ${error.message}`, { error });
                this.openGelocationDialog(btn);
                this.logger.log('fail', error);
            }

            btn.classList.remove('is-loading');
        });
    }

    async redirectToWeatherDetailPage () {
        const coordinates = await this.commonMethods.getGeolocation({ askForPermission: false });
        const weather = await this.getWeather(coordinates);

        if (weather && weather.link) {
            location.assign(weather.link);
        } else {
            this.logger.error('No weather link data found.', weather);
            alert('Laden von Wetterdaten fehlgeschlagen.');
        }
    }

    /**
     *
     * @param {Element} btn
     * @param {Boolean} closeable
     */
    openGelocationDialog (btn, closeable = true) {
        if (!this.GelocationDialogObject) {
            const id = (Math.random() + 1).toString(36).substring(7);
            const dialogNode = this.commonMethods.markupToElement(
                window.antenne.templates.geolocation(id),
            );
            document.body.append(dialogNode);
            this.GeolocationDialogWidget = dialogNode.querySelector('[data-geolocationwidget="location-settings"]');
            this.GelocationDialogObject = this.dialogService.initDialogNode(dialogNode, closeable);
        }
        btn.classList.remove('is-loading');
        this.GelocationDialogObject.show();
        this.handleLocationSettings(this.GeolocationDialogWidget);
    }
}

export default GeolocationWidget;
